import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../utils";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";
import { RUTAS } from "../constants/routes.constants";
import TranslatedLink from "../components/navigation/TranslatedLink";
import clsx from "clsx";
import icopdf from "../assets/ico-pdf.png";
import homevideo from "../assets/home-video.webm";
import Counter from "../components/layout/Counter";
import politicainocuidad from "../assets/politica-inocuidad.pdf";
import certificacioniso from "../assets/certificacion-iso.pdf";
import gueycertification from "../assets/guey-certification.pdf";
import jaliscocertification from "../assets/jalisco-certification.pdf";
import mexcertification from "../assets/mex-mango-certification.pdf";
import { FaCircle } from "react-icons/fa";
import { historyData } from "../utils/helpers";
import { useDownloads } from "../hooks/useDownloads";

function getEveryNth(arr, nth) {
  const result = [];

  for (let i = 0; i < arr.length; i += nth) result.push(arr.slice(i, i + nth));

  return result;
}

const PdfElem = ({ fullName, file, id }) => {
  return (
    <a href={file} state={{ id: id }} className="link" target="_blank">
      <div className="position-relative my-4 d-flex align-items-center">
        <img
          src={icopdf}
          alt={fullName}
          className="ico-pdf position-absolute"
        />
        <div className="mx-5">{fullName}</div>
      </div>
    </a>
  );
};

function Ladestileria({ intl }) {
  const [data, setData] = useState([]);
  const [activeDot, setActiveDot] = useState(-1);
  const [dataDownloads, setDataDownloads] = useState([]);
  const { handleFetchDownloads } = useDownloads();
  const text1 = useRef(null);
  const text2 = useRef(null);
  const text3 = useRef(null);
  const text4 = useRef(null);

  const handleDotClick = (index) => {
    if (index === 0) {
      setActiveDot(0);
      return;
    }
    setActiveDot(0);

    for (let i = 1; i <= index; i++) {
      setTimeout(() => {
        setActiveDot(i);
      }, i * 300);
    }
  };
  const lang = useLang();

  useEffect(() => {
    window.scrollTo(0, 0);
    const asyncFetchDownloads = async () => {
      const dataDownloads = await handleFetchDownloads({ featured: true });
      if (!dataDownloads?.length) return;
      setDataDownloads(
        dataDownloads
          .filter((d) => !d.download)
          .sort((a, b) => a.order - b.order)
      );
      // console.log(
      //   "DOWNLOADs: ",
      //   dataDownloads.filter((d) => !d.download)
      // );
    };
    asyncFetchDownloads();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("txt-animation");
          } else {
            entry.target.classList.remove("txt-animation");
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      }
    );

    [text1, text2, text3, text4].forEach((text) => {
      if (text.current) {
        observer.observe(text.current);
      }
    });

    return () => {
      [text1, text2, text3, text4].forEach((text) => {
        if (text.current) {
          observer.unobserve(text.current);
        }
      });
    };
  }, []);

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "LADESTILERIA.TITLESEO" }) || ""}
        description={intl.formatMessage({ id: "LADESTILERIA.DESCRIPTIONSEO" }) || ""}
      />
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-12 px-0 header-ladestileria">
            <video autoPlay loop muted className="video-background">
              <source src={homevideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-12 col-lg-6 offset-0 offset-lg-3 bggrey2 p-5 semitransp">
                  <h1 className="h1_55">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "LADESTILERIA.TEXT1",
                        }),
                      }}
                    />
                  </h1>
                  <h2 className="h2_43">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "LADESTILERIA.TEXT2",
                        }),
                      }}
                    />
                  </h2>
                  <p className="my-4">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "LADESTILERIA.TEXT3",
                        }),
                      }}
                    />
                  </p>
                  <div className="bullets-ladestileria">
                    <Counter
                      number={5}
                      title={
                        intl.formatMessage({
                          id: "LADESTILERIA.CONTINENTES",
                        }) || ""
                      }
                    />
                  </div>
                  <div className="bullets-ladestileria">
                    <Counter
                      number={20}
                      title={
                        intl.formatMessage({ id: "LADESTILERIA.PAISES" }) || ""
                      }
                    />
                  </div>
                  <div className="bullets-ladestileria">
                    <Counter
                      number={34}
                      title={
                        intl.formatMessage({ id: "LADESTILERIA.MARCAS" }) || ""
                      }
                    />
                  </div>
                  <div className="bullets-ladestileria">
                    <Counter
                      number={235}
                      title={
                        intl.formatMessage({
                          id: "LADESTILERIA.REFERENCIAS",
                        }) || ""
                      }
                    />
                  </div>
                  <p className="my-4">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "LADESTILERIA.TEXT4",
                        }),
                      }}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-6 order-2 order-lg-1 px-0 bg-nuestrosproductos"></div>
          <div className="col-12 col-lg-6 order-1 order-lg-2 p-5 d-flex align-items-center">
            <div ref={text1}>
              <h2 className="barlowmedium mb-5 h2_43">
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "LADESTILERIA.TEXT5" }),
                  }}
                />
              </h2>
              <p className="">
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "LADESTILERIA.TEXT6" }),
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-6 p-5 d-flex align-items-center">
            <p className="heeboregular">
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "LADESTILERIA.TEXT7" }),
                }}
              />
            </p>
          </div>
          <div className="col-12 col-lg-6 px-0" style={{ overflow: "hidden" }}>
            <div className="bg-jalisco"></div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-6 order-2 order-lg-1 px-0 bg-proyecto"></div>
          <div className="col-12 col-lg-6 order-1 order-lg-2 p-5 bggrey2 d-flex align-items-center">
            <div>
              <h2 className="barlowmedium mb-5 h2_43">
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "LADESTILERIA.TEXT12" }),
                  }}
                />
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "LADESTILERIA.TEXT8" }),
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 p-5 text-center">
            <h2 className="barlowmedium mb-5 h2_43">
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "LADESTILERIA.TEXT9" }),
                }}
                ref={text2}
              />
            </h2>
            <div className="px-0 px-lg-5 container-centered">
              <div
                className="w-75 mx-auto"
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "LADESTILERIA.TEXT10" }),
                }}
                ref={text3}
              />
            </div>
          </div>
        </div>
        <div className="row text-center py-4">
          {historyData.map((item, index) => (
            <div className="col-12 col-lg-3 my-4" key={index}>
              <section className="blockhist-container">
                <div className="dot-container">
                  {index !== historyData.length - 1 && (
                    <div
                      className="timeline-line"
                      style={{
                        width:
                          activeDot !== null && index <= activeDot - 1
                            ? "calc(120% - 70px)"
                            : "0",
                      }}
                    ></div>
                  )}
                  <div className="d-flex direction-row justify-content-center">
                    <FaCircle
                      className={`dot ${activeDot === index ? "active" : ""}`}
                      tabIndex="0"
                      // onClick={() => handleDotClick(index)}
                    />
                    {index != 3 && <div className="line"></div>}
                  </div>
                  <div className="my-2">{item.date}</div>
                  <img src={item.icon} className="icohist" height="100px" />
                  <div className="titlehist my-3">
                    {intl.formatMessage({
                      id: `LADESTILERIA.HISTORIA${index + 1}TITLE`,
                    })}
                  </div>
                </div>
                <div className="blockhist">{item.content}</div>
              </section>
            </div>
          ))}
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-8 px-0 bg-politicaalimentaria"></div>
          <div className="col-12 col-lg-4 p-5 bggrey2">
            <h2 className="barlowmedium mb-5">
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "LADESTILERIA.TEXT11" }),
                }}
              />
            </h2>
            <div>
              <div className="my-5">
                <div className="position-relative my-4">
                  {dataDownloads.map((download, index) => (
                    <div
                      className="position-relative my-4 d-flex align-items-center"
                      key={index}
                    >
                      <img
                        src={icopdf}
                        alt={download.fullName?.[lang]}
                        className="ico-pdf position-absolute"
                      />
                      <div className="mx-5">
                        <a
                          href={`${SERVER_URL}/${download.file?.[lang]}`}
                          target="_blank"
                          className="link"
                        >
                          <span>{download?.fullName?.[lang]}</span>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Ladestileria);

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, injectIntl } from "react-intl";
import { SERVER_URL } from "../../utils";
import { useLang } from "../../translations/i18n";
import pdf from "../../assets/ico-pdf.png";
import instagram from "../../assets/instagram.png";
import instagram_white from "../../assets/instagram_white.png";
import facebook from "../../assets/facebook.png";
import facebook_white from "../../assets/facebook_white.png";
import youtube from "../../assets/youtube.png";
import youtube_white from "../../assets/youtube_white.png";
import web from "../../assets/sitio-web.png";
import web_white from "../../assets/sitio-web_white.png";

function hexToRgb(hex) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function luminance(r, g, b) {
  const a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function contrast(colorHex) {
  const rgb = hexToRgb(colorHex);
  const lum = luminance(rgb.r, rgb.g, rgb.b);
  return lum > 0.179 ? "black" : "white";
}

const handleColor = (color) => {
  if (color && color != "") {
    // console.log("COLOR: ", color);
    const iconColor = contrast(color);
    // console.log("ICON_COLOR_RESULT: ", iconColor);
    if (iconColor === "white") return false;
    else return true;
  }
  return false;
};

function ProductCap({ product, formats, intl }) {
  const lang = useLang();
  const [selectedFormat, setSelectedFormat] = useState(null);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState("");
  const [backgroundMobileImageUrl, setBackgroundMobileImageUrl] = useState("");
  const [iconsWhite, setIconsWhite] = useState(handleColor(product?.color));
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (formats && formats.length > 0) {
      const firstFormat = formats[0];
      setSelectedFormat(firstFormat);
      setBackgroundImageUrl(`${SERVER_URL}/${firstFormat.mainImg}`);
      setBackgroundMobileImageUrl(`${SERVER_URL}/${firstFormat.miniImg}`);
    } else {
      setSelectedFormat(null);
      setBackgroundImageUrl(
        product ? `${SERVER_URL}/${product.mainImage}` : ""
      );
      setBackgroundMobileImageUrl(
        product ? `${SERVER_URL}/${product.footerImage}` : ""
      );
    }
  }, [product, formats]);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (formats && formats.length > 0) {
      setBackgroundImageUrl(`${SERVER_URL}/${selectedFormat?.mainImg}`);
      setBackgroundMobileImageUrl(`${SERVER_URL}/${selectedFormat?.miniImg}`);
    }
  }, [selectedFormat]);

  const socialMediaBlock = () => {
    return (
      <div className="d-flex flex-row social-media">
        <section className="d-flex align-items-center flex-row">
          {product?.socialMedia?.instagram && (
            <span className="my-2">
              <a
                href={product.socialMedia.instagram}
                style={{
                  marginLeft: "5px",
                  color: `${
                    isMobile ? "black" : iconsWhite ? "white" : "black"
                  }`,
                  textDecoration: "none",
                }}
                target="_blank"
              >
                <img
                  src={
                    iconsWhite
                      ? isMobile
                        ? instagram
                        : instagram_white
                      : instagram
                  }
                  alt="instagram-icon"
                  width="30"
                  height="30"
                />
              </a>
            </span>
          )}
        </section>
        <section className="d-flex align-items-center flex-row">
          {product?.socialMedia?.facebook && (
            <span className="my-2">
              <a
                href={product.socialMedia.facebook}
                style={{
                  marginLeft: "5px",
                  color: `${
                    isMobile ? "black" : iconsWhite ? "white" : "black"
                  }`,
                  textDecoration: "none",
                }}
                target="_blank"
              >
                <img
                  src={
                    iconsWhite
                      ? isMobile
                        ? facebook
                        : facebook_white
                      : facebook
                  }
                  alt="facebook-icon"
                  width="30"
                  height="30"
                />
              </a>
            </span>
          )}
        </section>
        <section className="d-flex align-items-center flex-row">
          {product?.socialMedia?.youtube && (
            <span className="my-2">
              <a
                href={product.socialMedia.youtube}
                style={{
                  marginLeft: "5px",
                  color: `${
                    isMobile ? "black" : iconsWhite ? "white" : "black"
                  }`,
                  textDecoration: "none",
                }}
                target="_blank"
              >
                <img
                  src={
                    iconsWhite ? (isMobile ? youtube : youtube_white) : youtube
                  }
                  alt="youtube-icon"
                  width="30"
                  height="30"
                />
              </a>
            </span>
          )}
        </section>
        <section className="d-flex align-items-center flex-row">
          {product?.socialMedia?.web && (
            <span className="my-2">
              <a
                href={product.socialMedia.web}
                style={{
                  marginLeft: "5px",
                  color: `${
                    isMobile ? "black" : iconsWhite ? "white" : "black"
                  }`,
                  textDecoration: "none",
                }}
                target="_blank"
              >
                <img
                  src={iconsWhite ? (isMobile ? web : web_white) : web}
                  alt="web-icon"
                  width="30"
                  height="30"
                />
              </a>
            </span>
          )}
        </section>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>{product.fullName[lang]}</title>
        <meta property="og:type" content="product" />
        <meta property="og:title" content={product.fullName[lang]} />
        <meta property="og:description" content={product.description[lang]} />
        <meta
          property="og:image"
          content={`${SERVER_URL}/${product.mainImage}`}
        />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={product.fullName[lang]} />
        <meta name="twitter:description" content={product.description[lang]} />
        <meta
          name="twitter:image"
          content={`${SERVER_URL}/${product.mainImage}`}
        />
      </Helmet>
      <div
        className={`container bgproductos p-0`}
        style={{
          backgroundImage: `${
            isMobile ? "none" : `url(${backgroundImageUrl})`
          }`,
          backgroundSize: `auto`,
        }}
      >
        {isMobile && (
          <section
            className="image-light"
            // style={{
            //   backgroundColor:
            //     product?.color &&
            //     product.color !== "" &&
            //     handleColor(product.color)
            //       ? "#000000"
            //       : "#FFFFFF",
            // }}
          >
            <img src={backgroundMobileImageUrl} alt="Bottle" className="w-50" />
          </section>
        )}
        <div
          className="d-flex justify-content-end h-100 w-100 px-5"
          style={{ "padding-top": "100px" }}
        >
          <div className="title-product">
            {product?.color && product.color != "" ? (
              <>
                <h4
                  className="productotitle heeboextrabold lh-1"
                  style={{ color: isMobile ? "black" : product?.color }}
                >
                  {product?.hideName ? "" : product?.fullName?.[lang]}
                </h4>
                <p
                  className="f-15 heeboregular product-mobile"
                  style={{ color: isMobile ? "black" : product?.color }}
                >
                  {product?.subtitle ? (
                    product?.subtitle?.[lang]
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: product?.description?.[lang],
                      }}
                    />
                  )}
                  {selectedFormat && (
                    <strong>
                      {intl.formatMessage({ id: "PRODUCTS.FORMATOBOTELLA" }) ||
                        ""}{" "}
                      {selectedFormat.quantity}
                    </strong>
                  )}
                  <div className="row">
                    {formats.length > 0 ? (
                      <strong>
                        {intl.formatMessage({
                          id: "PRODUCTS.SELECCIONAFORMATO",
                        }) || ""}
                      </strong>
                    ) : (
                      ""
                    )}
                    {formats.map((format) => (
                      <div className="col-6 col-md-3 mb-1" key={format._id}>
                        <img
                          src={`${SERVER_URL}/${format.miniImg}`}
                          alt={format.name}
                          className={`img-fluid img-format`}
                          onClick={() => setSelectedFormat(format)}
                        />
                      </div>
                    ))}
                  </div>
                  {product?.pdfFile && (
                    <a
                      href={`${SERVER_URL}/${product?.pdfFile}`}
                      target="_blank"
                      className="link d-flex justify-content-start"
                    >
                      <img
                        src={pdf}
                        alt="pdf_prod"
                        style={{ cursor: "pointer" }}
                      />
                      <p className="mx-2 align-self-center">
                        {product?.pdfName}
                      </p>
                    </a>
                  )}
                  {socialMediaBlock()}
                </p>
              </>
            ) : (
              <>
                <h4 className="productotitle heeboextrabold lh-1">
                  {product?.hideName ? "" : product?.fullName?.[lang]}
                </h4>
                <p className="f-15 heeboregular product-mobile">
                  {product?.subtitle && product.subtitle[lang].length > 0 ? (
                    product?.subtitle?.[lang]
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: product?.description?.[lang],
                      }}
                    />
                  )}
                  {selectedFormat && (
                    <strong>
                      {intl.formatMessage({ id: "PRODUCTS.FORMATOBOTELLA" }) ||
                        ""}{" "}
                      {selectedFormat.quantity}
                    </strong>
                  )}
                  <div className="row">
                    {formats.length > 0 ? (
                      <strong>
                        {intl.formatMessage({
                          id: "PRODUCTS.SELECCIONAFORMATO",
                        }) || ""}
                      </strong>
                    ) : (
                      ""
                    )}
                    {formats.map((format) => (
                      <div className="col-6 col-md-3 mb-1" key={format._id}>
                        <img
                          src={`${SERVER_URL}/${format.miniImg}`}
                          alt={format.name}
                          className={`img-fluid img-format`}
                          style={
                            selectedFormat?._id === format._id
                              ? { transform: "scale(1.1)" }
                              : {}
                          }
                          onClick={() => setSelectedFormat(format)}
                        />
                      </div>
                    ))}
                  </div>
                  {product?.pdfFile && (
                    <a
                      href={`${SERVER_URL}/${product?.pdfFile}`}
                      target="_blank"
                      className="link d-flex justify-content-start"
                    >
                      <img
                        src={pdf}
                        alt="pdf_prod"
                        style={{ cursor: "pointer" }}
                      />
                      <p className="mx-2 align-self-center">
                        {product?.pdfName}
                      </p>
                    </a>
                  )}
                  {socialMediaBlock()}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(ProductCap);

import hist1 from "../assets/history/hist1.png";
import hist2 from "../assets/history/hist2.png";
import hist3 from "../assets/history/hist3.png";
import hist4 from "../assets/history/hist4.png";
import icohist1 from "../assets/history/icohist1.png";
import icohist2 from "../assets/history/icohist2.png";
import icohist3 from "../assets/history/icohist3.png";
import icohist4 from "../assets/history/icohist4.png";
import icohistperson from "../assets/history/icohistperson.png";
import { FormattedMessage } from "react-intl";

export const historyData = [
  {
    date: "1940",
    icon: icohist1,
    title: "HISTORIA1TITLE",
    content: (
      <>
        <div className="my-5">
          <img src={icohistperson} className="icoperson" />
        </div>
        <div className="my-4">- - - - - - - - - </div>
        <div className="texthist">Julio Borrajo</div>
        <div className="my-4">- - - - - - - - - </div>
        <div className="texthist">
          <FormattedMessage id="LADESTILERIA.HISTORIA1TEXT" />
        </div>
      </>
    ),
  },
  {
    date: "1962",
    icon: icohist2,
    title: "HISTORIA2TITLE",
    content: (
      <>
        <div className="my-5">
          <img src={icohistperson} className="icoperson" />
        </div>
        <div className="my-4">- - - - - - - - - </div>
        <div className="texthist">Carlos Borrajo</div>
        <div className="my-4">- - - - - - - - - </div>
        <div className="texthist">
          <FormattedMessage id="LADESTILERIA.HISTORIA2TEXT" />
        </div>
      </>
    ),
  },
  {
    date: "1996",
    icon: icohist3,
    title: "HISTORIA3TITLE",
    content: (
      <>
        <div className="my-5">
          <img src={icohistperson} className="icoperson" />
        </div>
        <div className="my-4">- - - - - - - - - </div>
        <div className="texthist">
          Carlos Borrajo
          <br />
          Ana Borrajo
          <br />
          Luís Borrajo
        </div>
        <div className="my-4">- - - - - - - - - </div>
        <div className="texthist">
          <FormattedMessage id="LADESTILERIA.HISTORIA3TEXT" />
        </div>
      </>
    ),
  },
  {
    date: "2024",
    icon: icohist4,
    title: "HISTORIA4TITLE",
    content: (
      <>
        <div className="my-5">
          <img src={icohistperson} className="icoperson" />
        </div>
        <div className="my-4">- - - - - - - - - </div>
        <div className="texthist">
          Carlos Borrajo
          <br />
          Ana Borrajo
          <br />
          Luís Borrajo
        </div>
        <div className="my-4">- - - - - - - - - </div>
        <div className="texthist">
          <FormattedMessage id="LADESTILERIA.HISTORIA4TEXT" />
        </div>
      </>
    ),
  },
];

import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";

function PoliticaPrivacidad({ intl }) {
  const lang = useLang();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let txt = (
    <>
      <p>
        <strong>TITULARIDAD DEL PORTAL</strong>
      </p>
      <p>
        De acuerdo con el establecido en la ley 34/2002 de 11 de julio de
        Servicios de la Sociedad de la Informaci&oacute;n y de Comercio
        Electr&oacute;nico (en adelante LSSICE), se indican los datos de
        informaci&oacute;n general de este sitio web:
      </p>
      <ul>
        <li>
          Titular: Internacional de Comercio y Destiler&iacute;as J.Borrajo S.A (En
          Adelante Borrajo)
        </li>
        <li>
          Domicilio: C/ Francisco Redondo, 10. Talavera de la Reina, Toledo
          (Spain)
        </li>
      </ul>
      <ul>
        <li>
          Contacto: <a href="mailto:cial@jborrajo.com">cial@jborrajo.com</a>
        </li>
      </ul>
      <ul>
        <li>
          Tel&eacute;fono de Atenci&oacute;n al Cliente:{" "}
          <a href="tel:925802004">925802004</a>
        </li>
      </ul>
      <p>
        El domicilio a efectos de reclamaciones corresponder&aacute; con el
        indicado como domicilio social de la empresa.
      </p>
      <p>
        Borrajo, es una sociedad an&oacute;nima con C.I.F. A28928885, inscrita
        en el Registro Mercantil de inscripci&oacute;n xxxxxxxxx de Toledo, Tomo
        xxxxxxxxxx, Folio xxxxxxxxxx, H.B. xxxxxxxxxx. Es titular del
        portal&nbsp;www.destileriasjborrajo.net&nbsp;(en adelante el portal, la
        web o la tienda indistintamente) y lo pone a disposici&oacute;n de los
        usuarios de Internet, con el fin de permitir la adquisici&oacute;n de
        los productos comercializados por la compa&ntilde;&iacute;a y/o de
        terceros si fuere el caso.
      </p>
      <p>
        <strong>PROTECCI&Oacute;N DE DATOS</strong>
      </p>
      <p>
        Los datos recogidos a trav&eacute;s de la web, o de los mecanismos
        indicados en ella, ser&aacute;n incorporados en los ficheros titularidad
        de BORRAJO. Puede consultar la Ley de Privacidad
        en&nbsp;https://destileriasjborrajo.net/privacidad
      </p>
      <p>
        <strong>Tratamiento de datos</strong>
      </p>
      <p>Sus datos ser&aacute;n utilizados para:</p>
      <ul>
        <li>
          Perfeccionar, cumplir y controlar el correcto cumplimiento del
          contrato y de las obligaciones legales de Borrajo.
        </li>
        <li>
          Gestionar su cuenta de usuario, el acceso y las acciones que se
          realicen como usuario registrado.
        </li>
        <li>
          Enviarle newsletters/boletines de noticias e informaci&oacute;n,
          pudiendo darse de baja en cualquier momento bien a trav&eacute;s del
          propio mail donde se habilitar&aacute; una direcci&oacute;n
          electr&oacute;nica o entrando en &ldquo;Mi cuenta&rdquo; si ya es
          usuario registrado.
        </li>
        <li>
          Mandarle comunicaciones promocionales sobre los productos de Borrajo
          que tengan que ver con su compra o en caso de que as&iacute; nos lo
          haya solicitado de cualquier otro producto u oferta comercial.
        </li>
      </ul>
      <p>
        Los datos requeridos a trav&eacute;s de los formularios o registros que
        tengan un asterisco (*) son obligatorios y sin ellos no podr&aacute;n
        realizarse las gestiones solicitadas por el usuario.
      </p>
      <p>
        <strong>Comunicaciones comerciales, promocionales y newsletters</strong>
      </p>
      <ul>
        <li>
          Contenido de las comunicaciones: Borrajo podr&aacute; enviarle
          comunicaciones promocionales relacionadas con los productos
          disponibles en nuestra web, nunca lo har&aacute; de terceros salvo que
          le informe previamente y con su consentimiento.
        </li>
        <li>
          Medios: Borrajo le enviar&aacute; dichas comunicaciones por cualquier
          medio (correo postal, telef&oacute;nico, email o cualquier otro medio
          electr&oacute;nico).
        </li>
      </ul>
      <p>
        En relaci&oacute;n a las comunicaciones promocionales por medios
        electr&oacute;nicos, le informaremos en cada una de las mismas,
        c&oacute;mo darle de baja de una forma gratuita y sencilla. Atenderemos
        su solicitud tan pronto como sea posible y, en cualquier caso, dentro
        del plazo legal establecido.
      </p>
      <p>
        <strong>Modificaci&oacute;n de los datos y/o bajas</strong>
      </p>
      <p>
        Podr&aacute; contactar con nosotros en BORRAJO C/ Francisco Redondo, 10.
        Talavera de la Reina, Toledo (Spain), indicando en el asunto &ndash;
        &ldquo;RGPD&rdquo; &ndash; para cualquier cuesti&oacute;n relacionada
        con sus datos personales- y podr&aacute; ejercer su derecho de acceso,
        rectificaci&oacute;n, cancelaci&oacute;n, limitaci&oacute;n,
        portabilidad y oposici&oacute;n en cualquier momento (sin perjuicio del
        procedimiento espec&iacute;fico para las comunicaciones promocionales
        con fines publicitarios por medios electr&oacute;nicos).
      </p>
      <p>
        <strong>Datos de terceros</strong>
      </p>
      <p>
        Antes de que nos facilite cualquier dato de terceros, debe haberle
        informado y haber obtenido su consentimiento de acuerdo con
        nuestra&nbsp;
        <a href="https://destileriasjborrajo.net/politica-privacidad/">
          Pol&iacute;tica de Privacidad
        </a>
        .
      </p>
      <p>
        Borrajo no toma ninguna decisi&oacute;n en relaci&oacute;n a este
        env&iacute;o y s&oacute;lo seguir&aacute; sus instrucciones. El
        env&iacute;o ser&aacute; inmediato, por lo que no ser&aacute; posible la
        posterior correcci&oacute;n de la direcci&oacute;n referida. Debe saber
        que las medidas de seguridad de nuestros sistemas para la
        prestaci&oacute;n de este servicio son de nivel b&aacute;sico.
      </p>
      <p>
        No usaremos o facilitaremos los datos revelados por usted para
        ning&uacute;n otro prop&oacute;sito diferente a la transmisi&oacute;n de
        su mensaje y, una vez finalizado este env&iacute;o solicitado por usted,
        no guardaremos estos datos ni ninguna informaci&oacute;n, salvo que la
        ley as&iacute; lo establezca.
      </p>
      <p>
        <strong>
          ACEPTACI&Oacute;N DE LAS CONDICIONES GENERALES DE USO Y
          CONTRATACI&Oacute;N DEL SITIO WEB
        </strong>
      </p>
      <ul>
        <li>
          A continuaci&oacute;n, se expone el documento contractual que
          regir&aacute; la contrataci&oacute;n de productos y/o servicios a
          trav&eacute;s del sitio web&nbsp;
          <a href="https://destileriasjborrajo.net/">destilerias</a>
          &nbsp;propiedad de Borrajo (en adelante el prestador). La
          aceptaci&oacute;n del presente documento conlleva que el usuario:
        </li>
        <ul>
          <li>
            Ha le&iacute;do, entiende y comprende lo aqu&iacute; expuesto.
          </li>
          <li>Que es una persona con capacidad suficiente para contratar.</li>
          <li>Que asume todas las obligaciones aqu&iacute; dispuestas.</li>
        </ul>
        <li>
          Las presentes condiciones tendr&aacute;n un periodo de validez
          indefinido y ser&aacute;n aplicables a todas las contrataciones
          realizadas a trav&eacute;s del sitio web www.destileriasjborrajo.net
        </li>
        <li>
          Las presentes Condiciones Generales de Uso y Contrataci&oacute;n del
          sitio web de Borrajo, junto con las Condiciones Particulares que
          puedan establecerse, tienen como finalidad poner a disposici&oacute;n
          del usuario informaci&oacute;n necesaria y regular las relaciones
          comerciales que surjan entre Borrajo y los usuarios de la web. Tanto
          la navegaci&oacute;n, como el registro, la utilizaci&oacute;n y/o la
          adquisici&oacute;n de cualquiera de los productos de la web, suponen
          la aceptaci&oacute;n como Usuario, sin reservas de ninguna clase, de
          todas y cada una de las presentes Condiciones Generales de Uso y
          Contrataci&oacute;n que en su caso rijan la adquisici&oacute;n de los
          bienes y/o la prestaci&oacute;n de los servicios, as&iacute; como en
          su caso, de las Condiciones Particulares si las hubiere. Borrajo
          podr&aacute; en todo momento y sin previo aviso, modificar las
          presentes Condiciones Generales de Uso y Contrataci&oacute;n y las
          Condiciones Particulares mediante la publicaci&oacute;n de dichas
          modificaciones en la web para que puedan ser conocidas por los
          Usuarios. Dicha modificaci&oacute;n no afectar&aacute; a los bienes o
          promociones que fueron adquiridos por el Usuario previamente a la
          modificaci&oacute;n.
        </li>
        <li>
          Cookies: Para la utilizaci&oacute;n de nuestro sitio web es necesaria
          la utilizaci&oacute;n de cookies. Las cookies se utilizan con la
          finalidad de ajustar nuestras ofertas a las necesidades de los
          usuarios. Si usted lo desea puede ajustar la configuraci&oacute;n de
          su navegador para ser avisado en pantalla de la recepci&oacute;n de
          cookies y para impedir la instalaci&oacute;n de cookies en su disco
          duro. Por favor consulte las instrucciones y manuales de su navegador
          para ampliar esta informaci&oacute;n, y tambi&eacute;n
          nuestra&nbsp;Pol&iacute;tica de Cookies.
        </li>
      </ul>
      <p>
        Se aconseja al Usuario leer con detenimiento las presentes condiciones.
      </p>
      <p>
        <strong>DEFINICIONES</strong>
      </p>
      <p>
        Borrajo: Borrajo SA. Prestador de servicios de la sociedad de la
        informaci&oacute;n de conformidad con la LSSICE y titular de la
        web&nbsp;Borrajo.com
      </p>
      <p>
        Usuario: Persona que acceda a la p&aacute;gina
        web&nbsp;Borrajo.com&nbsp;bien directamente o bien a trav&eacute;s de
        otras p&aacute;ginas o sitios web, y se registre con el fin de adquirir
        productos para su consumo final
      </p>
      <p>
        Condiciones Generales de Contrataci&oacute;n (CGC): son las presentes
        condiciones generales de contrataci&oacute;n que regulan la
        relaci&oacute;n contractual entre Borrajo y el Usuario.
      </p>
      <p>
        <strong>IDENTIDAD DE LAS PARTES</strong>
      </p>
      <p>
        Por una parte, el proveedor de los bienes o servicios contratados por el
        usuario es Borrajo con domicilio social C/ Francisco Redondo, 10.
        Talavera de la Reina, Toledo (Spain)
      </p>
      <p>
        Y de otra parte el usuario registrado en el sitio web mediante un nombre
        de usuario y contrase&ntilde;a.
      </p>
      <p>
        <strong>REGISTRO DE USUARIOS</strong>
      </p>
      <p>
        El Usuario, podr&aacute; registrarse en la web, en el apartado
        &ldquo;Iniciar Sesi&oacute;n&rdquo;. Para ello, deber&aacute; introducir
        los datos personales requeridos como obligatorios. El Alta como Usuario
        de la tienda le proporcionar&aacute; acceso a la web, a la
        adquisici&oacute;n de productos y a la recepci&oacute;n de boletines
        informativos (Newsletter) con informaci&oacute;n de la
        compa&ntilde;&iacute;a y de sus productos.
      </p>
      <p>
        El usuario seleccionar&aacute; un nombre de usuario y una
        contrase&ntilde;a, comprometi&eacute;ndose a hacer uso diligente de los
        mismos, y no ponerlos a disposici&oacute;n de terceros, as&iacute; como
        a comunicar al prestador la p&eacute;rdida o robo de los mismos o del
        posible acceso por un tercero no autorizado, de tal forma que
        &eacute;ste proceda al bloqueo inmediato. El usuario tiene
        responsabilidad plena de su uso y custodia, siendo responsable de la
        veracidad de los datos personales facilitados al prestador.
      </p>
      <p>
        El usuario no podr&aacute; elegir como nombre de usuario palabras que
        tengan como fin el confundir a otros por identificar a &eacute;ste como
        miembro integrante del prestador, as&iacute; como, expresiones
        malsonantes, injuriosas y en general, contrarias a la ley o a las
        exigencias de la moral y buenas costumbres.
      </p>
      <p>
        <strong>INFORMACI&Oacute;N SOBRE PRODUCTOS</strong>
      </p>
      <p>
        Las descripciones de los productos ofrecidos en el portal se realizan en
        base al cat&aacute;logo de productos de Borrajo.
      </p>
      <p>
        Las fotograf&iacute;as, representaciones gr&aacute;ficas o
        iconogr&aacute;ficas y v&iacute;deos relativos a los productos,
        as&iacute; como nombres comerciales, marcas o signos distintivos de
        cualquier clase contenidos en el sitio web tienen por objeto aportar la
        mayor informaci&oacute;n, no obstante, el Usuario ha de tener en cuenta
        que tienen un prop&oacute;sito orientativo y, en consecuencia, carecen
        de car&aacute;cter exhaustivo.
      </p>
      <p>
        Es necesario advertir al usuario que en algunos casos las
        im&aacute;genes ofrecidas con la descripci&oacute;n del producto pueden
        no coincidir exactamente, en estos casos prevalecer&aacute; siempre la
        descripci&oacute;n del producto que se hace en la ficha de compra. Estos
        casos, ser&aacute;n excepcionales ya que la intenci&oacute;n de Borrajo
        es ofrecer siempre una imagen fiel del producto ofertado.
      </p>
      <p>
        Asimismo, para garantizar informaci&oacute;n m&aacute;s completa
        tambi&eacute;n le indicaremos la existencia o no de stock del producto
        lo antes posible. En el caso en que el producto no est&eacute;
        disponible despu&eacute;s de haberse realizado la compra, Borrajo
        informar&aacute; al Usuario de la anulaci&oacute;n total o, en su caso,
        parcial del pedido y de la devoluci&oacute;n del precio si procede.
      </p>
      <p>
        <strong>PROPIEDAD INTELECTUAL</strong>
      </p>
      <p>
        El usuario reconoce y acepta no realizar ninguna actividad que vaya en
        contra (a t&iacute;tulo enunciativo y no limitativo) del copyright,
        marca registrada, logotipos, textos, fotograf&iacute;as, iconos,
        im&aacute;genes, etc., as&iacute; como el dise&ntilde;o gr&aacute;fico,
        c&oacute;digo fuente, derechos de propiedad intelectual, ni sobre los
        materiales o contenidos que se aportan como parte de la p&aacute;gina
        web, ya que son de Borrajo o de terceros.
      </p>
      <p>
        La informaci&oacute;n p&uacute;blica contenida en la p&aacute;gina web
        de Borrajo referida tanto a la compa&ntilde;&iacute;a Borrajo como a las
        marcas, productos, logotipos, etc. de terceros se encuentran protegidas
        por las disposiciones legales sobre propiedad intelectual e industrial,
        por lo que no se autoriza la copia, transmisi&oacute;n, cesi&oacute;n,
        enajenaci&oacute;n o utilizaci&oacute;n por el Usuario ajenas a la
        finalidad publicitaria de su publicaci&oacute;n virtual, salvo que
        cuenten con el consentimiento expreso de Borrajo, o del tercero titular.
      </p>
      <p>
        <strong>
          VIRUS, PIRATER&Iacute;A Y OTROS ATAQUES INFORM&Aacute;TICOS
        </strong>
      </p>
      <p>
        El usuario se obliga a no realizar un uso indebido de esta p&aacute;gina
        web mediante la introducci&oacute;n intencionada en la misma de virus,
        troyanos, gusanos, bombas l&oacute;gicas o cualquier otro programa o
        material tecnol&oacute;gicamente perjudicial o da&ntilde;ino. No
        tratar&aacute; de tener acceso no autorizado a esta p&aacute;gina web,
        al servidor en que dicha p&aacute;gina se encuentra alojada, ordenador o
        base de datos relacionada con nuestra p&aacute;gina web. Se compromete a
        no atacar esta p&aacute;gina web a trav&eacute;s de un ataque de
        denegaci&oacute;n de servicio o de un ataque de denegaci&oacute;n de
        servicio distribuido.
      </p>
      <p>
        El incumplimiento de esta cl&aacute;usula podr&iacute;a llevar aparejada
        la comisi&oacute;n de infracciones tipificadas como delito o
        infracci&oacute;n por la normativa aplicable. Informaremos de cualquier
        incumplimiento de dicha normativa a las autoridades competentes y
        cooperaremos con ellas para descubrir la identidad del atacante.
        Asimismo, en caso de incumplimiento de la presente cl&aacute;usula,
        dejar&aacute; inmediatamente de estar autorizado a usar esta
        p&aacute;gina web.
      </p>
      <p>
        No seremos responsables de cualquier da&ntilde;o o p&eacute;rdida
        resultante de un ataque de denegaci&oacute;n de servicio, virus o
        cualquier otro programa o material tecnol&oacute;gicamente perjudicial o
        da&ntilde;ino que pueda afectar a su ordenador, datos o materiales como
        consecuencia del uso de esta p&aacute;gina web o de la descarga de
        contenidos de la misma o a los que la misma pueda redireccionar.
      </p>
      <p>
        Borrajo no responder&aacute; por las interrupciones que se produzcan en
        los servicios el&eacute;ctricos o de telecomunicaciones que impidan a
        los Usuarios utilizar los servicios ofrecidos.
      </p>
      <p>
        <strong>LEY APLICABLE Y JURISDICCI&Oacute;N</strong>
      </p>
      <p>
        Las presentes Condiciones Generales se rigen por la legislaci&oacute;n
        espa&ntilde;ola.
      </p>
      <p>
        En caso de controversia o desavenencia que se derive de la compra de
        productos a trav&eacute;s de la web y/o de las presentes Condiciones
        Generales y para la resoluci&oacute;n de cualesquiera conflictos, las
        partes acuerdan someterse a los Tribunales de Madrid, salvo que por
        derecho corresponda el fuero al domicilio del Usuario.
      </p>
    </>
  );

  if (lang === "ca")
    txt = (
      <>
        <p>
          <strong>TITULARIDAD DEL PORTAL</strong>
        </p>
        <p>
          De acuerdo con el establecido en la ley 34/2002 de 11 de julio de
          Servicios de la Sociedad de la Informaci&oacute;n y de Comercio
          Electr&oacute;nico (en adelante LSSICE), se indican los datos de
          informaci&oacute;n general de este sitio web:
        </p>
        <ul>
          <li>
            Titular: Intern. de Comercio y Destiler&iacute;as J.Borrajo S.A (En
            Adelante Borrajo)
          </li>
          <li>
            Domicilio: C/ Francisco Redondo, 10. Talavera de la Reina, Toledo
            (Spain)
          </li>
        </ul>
        <ul>
          <li>
            Contacto:info@<a href="http://destileriasborrajo.net/">net</a>
          </li>
        </ul>
        <ul>
          <li>Tel&eacute;fono de Atenci&oacute;n al Cliente: 925802004</li>
        </ul>
        <p>
          El domicilio a efectos de reclamaciones corresponder&aacute; con el
          indicado como domicilio social de la empresa.
        </p>
        <p>
          Borrajo, es una sociedad an&oacute;nima con C.I.F. A28928885, inscrita
          en el Registro Mercantil de inscripci&oacute;n xxxxxxxxx de Toledo,
          Tomo xxxxxxxxxx, Folio xxxxxxxxxx, H.B. xxxxxxxxxx. Es titular del
          portal&nbsp;www.destileriasjborrajo.net&nbsp;(en adelante el portal,
          la web o la tienda indistintamente) y lo pone a disposici&oacute;n de
          los usuarios de Internet, con el fin de permitir la adquisici&oacute;n
          de los productos comercializados por la compa&ntilde;&iacute;a y/o de
          terceros si fuere el caso.
        </p>
        <p>
          <strong>PROTECCI&Oacute;N DE DATOS</strong>
        </p>
        <p>
          Los datos recogidos a trav&eacute;s de la web, o de los mecanismos
          indicados en ella, ser&aacute;n incorporados en los ficheros
          titularidad de BORRAJO. Puede consultar la Ley de Privacidad
          en&nbsp;https://www.destileriasjborrajo.net/privacidad
        </p>
        <p>
          <strong>Tratamiento de datos</strong>
        </p>
        <p>Sus datos ser&aacute;n utilizados para:</p>
        <ul>
          <li>
            Perfeccionar, cumplir y controlar el correcto cumplimiento del
            contrato y de las obligaciones legales de Borrajo.
          </li>
          <li>
            Gestionar su cuenta de usuario, el acceso y las acciones que se
            realicen como usuario registrado.
          </li>
          <li>
            Enviarle newsletters/boletines de noticias e informaci&oacute;n,
            pudiendo darse de baja en cualquier momento bien a trav&eacute;s del
            propio mail donde se habilitar&aacute; una direcci&oacute;n
            electr&oacute;nica o entrando en &ldquo;Mi cuenta&rdquo; si ya es
            usuario registrado.
          </li>
          <li>
            Mandarle comunicaciones promocionales sobre los productos de Borrajo
            que tengan que ver con su compra o en caso de que as&iacute; nos lo
            haya solicitado de cualquier otro producto u oferta comercial.
          </li>
        </ul>
        <p>
          Los datos requeridos a trav&eacute;s de los formularios o registros
          que tengan un asterisco (*) son obligatorios y sin ellos no
          podr&aacute;n realizarse las gestiones solicitadas por el usuario.
        </p>
        <p>
          <strong>
            Comunicaciones comerciales, promocionales y newsletters
          </strong>
        </p>
        <ul>
          <li>
            Contenido de las comunicaciones: Borrajo podr&aacute; enviarle
            comunicaciones promocionales relacionadas con los productos
            disponibles en nuestra web, nunca lo har&aacute; de terceros salvo
            que le informe previamente y con su consentimiento.
          </li>
          <li>
            Medios: Borrajo le enviar&aacute; dichas comunicaciones por
            cualquier medio (correo postal, telef&oacute;nico, email o cualquier
            otro medio electr&oacute;nico).
          </li>
        </ul>
        <p>
          En relaci&oacute;n a las comunicaciones promocionales por medios
          electr&oacute;nicos, le informaremos en cada una de las mismas,
          c&oacute;mo darle de baja de una forma gratuita y sencilla.
          Atenderemos su solicitud tan pronto como sea posible y, en cualquier
          caso, dentro del plazo legal establecido.
        </p>
        <p>
          <strong>Modificaci&oacute;n de los datos y/o bajas</strong>
        </p>
        <p>
          Podr&aacute; contactar con nosotros en BORRAJO C/ Francisco Redondo,
          10. Talavera de la Reina, Toledo (Spain), indicando en el asunto
          &ndash; &ldquo;RGPD&rdquo; &ndash; para cualquier cuesti&oacute;n
          relacionada con sus datos personales- y podr&aacute; ejercer su
          derecho de acceso, rectificaci&oacute;n, cancelaci&oacute;n,
          limitaci&oacute;n, portabilidad y oposici&oacute;n en cualquier
          momento (sin perjuicio del procedimiento espec&iacute;fico para las
          comunicaciones promocionales con fines publicitarios por medios
          electr&oacute;nicos).
        </p>
        <p>
          <strong>Datos de terceros</strong>
        </p>
        <p>
          Antes de que nos facilite cualquier dato de terceros, debe haberle
          informado y haber obtenido su consentimiento de acuerdo con
          nuestra&nbsp;
          <a href="https://destileriasjborrajo.net/privacy/">
            Pol&iacute;tica de Privacidad
          </a>
          .
        </p>
        <p>
          Borrajo no toma ninguna decisi&oacute;n en relaci&oacute;n a este
          env&iacute;o y s&oacute;lo seguir&aacute; sus instrucciones. El
          env&iacute;o ser&aacute; inmediato, por lo que no ser&aacute; posible
          la posterior correcci&oacute;n de la direcci&oacute;n referida. Debe
          saber que las medidas de seguridad de nuestros sistemas para la
          prestaci&oacute;n de este servicio son de nivel b&aacute;sico.
        </p>
        <p>
          No usaremos o facilitaremos los datos revelados por usted para
          ning&uacute;n otro prop&oacute;sito diferente a la transmisi&oacute;n
          de su mensaje y, una vez finalizado este env&iacute;o solicitado por
          usted, no guardaremos estos datos ni ninguna informaci&oacute;n, salvo
          que la ley as&iacute; lo establezca.
        </p>
        <p>
          <strong>
            ACEPTACI&Oacute;N DE LAS CONDICIONES GENERALES DE USO Y
            CONTRATACI&Oacute;N DEL SITIO WEB
          </strong>
        </p>
        <ul>
          <li>
            A continuaci&oacute;n, se expone el documento contractual que
            regir&aacute; la contrataci&oacute;n de productos y/o servicios a
            trav&eacute;s del sitio web&nbsp;
            <a href="https://destileriasjborrajo.net/">destilerias</a>
            &nbsp;propiedad de Borrajo (en adelante el prestador). La
            aceptaci&oacute;n del presente documento conlleva que el usuario:
          </li>
          <ul>
            <li>
              Ha le&iacute;do, entiende y comprende lo aqu&iacute; expuesto.
            </li>
            <li>Que es una persona con capacidad suficiente para contratar.</li>
            <li>Que asume todas las obligaciones aqu&iacute; dispuestas.</li>
          </ul>
          <li>
            Las presentes condiciones tendr&aacute;n un periodo de validez
            indefinido y ser&aacute;n aplicables a todas las contrataciones
            realizadas a trav&eacute;s del sitio web www.destileriasjborrajo.net
          </li>
          <li>
            Las presentes Condiciones Generales de Uso y Contrataci&oacute;n del
            sitio web de Borrajo, junto con las Condiciones Particulares que
            puedan establecerse, tienen como finalidad poner a
            disposici&oacute;n del usuario informaci&oacute;n necesaria y
            regular las relaciones comerciales que surjan entre Borrajo y los
            usuarios de la web. Tanto la navegaci&oacute;n, como el registro, la
            utilizaci&oacute;n y/o la adquisici&oacute;n de cualquiera de los
            productos de la web, suponen la aceptaci&oacute;n como Usuario, sin
            reservas de ninguna clase, de todas y cada una de las presentes
            Condiciones Generales de Uso y Contrataci&oacute;n que en su caso
            rijan la adquisici&oacute;n de los bienes y/o la prestaci&oacute;n
            de los servicios, as&iacute; como en su caso, de las Condiciones
            Particulares si las hubiere. Borrajo podr&aacute; en todo momento y
            sin previo aviso, modificar las presentes Condiciones Generales de
            Uso y Contrataci&oacute;n y las Condiciones Particulares mediante la
            publicaci&oacute;n de dichas modificaciones en la web para que
            puedan ser conocidas por los Usuarios. Dicha modificaci&oacute;n no
            afectar&aacute; a los bienes o promociones que fueron adquiridos por
            el Usuario previamente a la modificaci&oacute;n.
          </li>
          <li>
            Cookies: Para la utilizaci&oacute;n de nuestro sitio web es
            necesaria la utilizaci&oacute;n de cookies. Las cookies se utilizan
            con la finalidad de ajustar nuestras ofertas a las necesidades de
            los usuarios. Si usted lo desea puede ajustar la
            configuraci&oacute;n de su navegador para ser avisado en pantalla de
            la recepci&oacute;n de cookies y para impedir la instalaci&oacute;n
            de cookies en su disco duro. Por favor consulte las instrucciones y
            manuales de su navegador para ampliar esta informaci&oacute;n, y
            tambi&eacute;n nuestra&nbsp;Pol&iacute;tica de Cookies.
          </li>
        </ul>
        <p>
          Se aconseja al Usuario leer con detenimiento las presentes
          condiciones.
        </p>
        <p>
          <strong>DEFINICIONES</strong>
        </p>
        <p>
          Borrajo: Borrajo SA. Prestador de servicios de la sociedad de la
          informaci&oacute;n de conformidad con la LSSICE y titular de la
          web&nbsp;Borrajo.com
        </p>
        <p>
          Usuario: Persona que acceda a la p&aacute;gina
          web&nbsp;Borrajo.com&nbsp;bien directamente o bien a trav&eacute;s de
          otras p&aacute;ginas o sitios web, y se registre con el fin de
          adquirir productos para su consumo final
        </p>
        <p>
          Condiciones Generales de Contrataci&oacute;n (CGC): son las presentes
          condiciones generales de contrataci&oacute;n que regulan la
          relaci&oacute;n contractual entre Borrajo y el Usuario.
        </p>
        <p>
          <strong>IDENTIDAD DE LAS PARTES</strong>
        </p>
        <p>
          Por una parte, el proveedor de los bienes o servicios contratados por
          el usuario es Borrajo con domicilio social C/ Francisco Redondo, 10.
          Talavera de la Reina, Toledo (Spain)
        </p>
        <p>
          Y de otra parte el usuario registrado en el sitio web mediante un
          nombre de usuario y contrase&ntilde;a.
        </p>
        <p>
          <strong>REGISTRO DE USUARIOS</strong>
        </p>
        <p>
          El Usuario, podr&aacute; registrarse en la web, en el apartado
          &ldquo;Iniciar Sesi&oacute;n&rdquo;. Para ello, deber&aacute;
          introducir los datos personales requeridos como obligatorios. El Alta
          como Usuario de la tienda le proporcionar&aacute; acceso a la web, a
          la adquisici&oacute;n de productos y a la recepci&oacute;n de
          boletines informativos (Newsletter) con informaci&oacute;n de la
          compa&ntilde;&iacute;a y de sus productos.
        </p>
        <p>
          El usuario seleccionar&aacute; un nombre de usuario y una
          contrase&ntilde;a, comprometi&eacute;ndose a hacer uso diligente de
          los mismos, y no ponerlos a disposici&oacute;n de terceros, as&iacute;
          como a comunicar al prestador la p&eacute;rdida o robo de los mismos o
          del posible acceso por un tercero no autorizado, de tal forma que
          &eacute;ste proceda al bloqueo inmediato. El usuario tiene
          responsabilidad plena de su uso y custodia, siendo responsable de la
          veracidad de los datos personales facilitados al prestador.
        </p>
        <p>
          El usuario no podr&aacute; elegir como nombre de usuario palabras que
          tengan como fin el confundir a otros por identificar a &eacute;ste
          como miembro integrante del prestador, as&iacute; como, expresiones
          malsonantes, injuriosas y en general, contrarias a la ley o a las
          exigencias de la moral y buenas costumbres.
        </p>
        <p>
          <strong>INFORMACI&Oacute;N SOBRE PRODUCTOS</strong>
        </p>
        <p>
          Las descripciones de los productos ofrecidos en el portal se realizan
          en base al cat&aacute;logo de productos de Borrajo.
        </p>
        <p>
          Las fotograf&iacute;as, representaciones gr&aacute;ficas o
          iconogr&aacute;ficas y v&iacute;deos relativos a los productos,
          as&iacute; como nombres comerciales, marcas o signos distintivos de
          cualquier clase contenidos en el sitio web tienen por objeto aportar
          la mayor informaci&oacute;n, no obstante, el Usuario ha de tener en
          cuenta que tienen un prop&oacute;sito orientativo y, en consecuencia,
          carecen de car&aacute;cter exhaustivo.
        </p>
        <p>
          Es necesario advertir al usuario que en algunos casos las
          im&aacute;genes ofrecidas con la descripci&oacute;n del producto
          pueden no coincidir exactamente, en estos casos prevalecer&aacute;
          siempre la descripci&oacute;n del producto que se hace en la ficha de
          compra. Estos casos, ser&aacute;n excepcionales ya que la
          intenci&oacute;n de Borrajo es ofrecer siempre una imagen fiel del
          producto ofertado.
        </p>
        <p>
          Asimismo, para garantizar informaci&oacute;n m&aacute;s completa
          tambi&eacute;n le indicaremos la existencia o no de stock del producto
          lo antes posible. En el caso en que el producto no est&eacute;
          disponible despu&eacute;s de haberse realizado la compra, Borrajo
          informar&aacute; al Usuario de la anulaci&oacute;n total o, en su
          caso, parcial del pedido y de la devoluci&oacute;n del precio si
          procede.
        </p>
        <p>
          <strong>PROPIEDAD INTELECTUAL</strong>
        </p>
        <p>
          El usuario reconoce y acepta no realizar ninguna actividad que vaya en
          contra (a t&iacute;tulo enunciativo y no limitativo) del copyright,
          marca registrada, logotipos, textos, fotograf&iacute;as, iconos,
          im&aacute;genes, etc., as&iacute; como el dise&ntilde;o
          gr&aacute;fico, c&oacute;digo fuente, derechos de propiedad
          intelectual, ni sobre los materiales o contenidos que se aportan como
          parte de la p&aacute;gina web, ya que son de Borrajo o de terceros.
        </p>
        <p>
          La informaci&oacute;n p&uacute;blica contenida en la p&aacute;gina web
          de Borrajo referida tanto a la compa&ntilde;&iacute;a Borrajo como a
          las marcas, productos, logotipos, etc. de terceros se encuentran
          protegidas por las disposiciones legales sobre propiedad intelectual e
          industrial, por lo que no se autoriza la copia, transmisi&oacute;n,
          cesi&oacute;n, enajenaci&oacute;n o utilizaci&oacute;n por el Usuario
          ajenas a la finalidad publicitaria de su publicaci&oacute;n virtual,
          salvo que cuenten con el consentimiento expreso de Borrajo, o del
          tercero titular.
        </p>
        <p>
          <strong>
            VIRUS, PIRATER&Iacute;A Y OTROS ATAQUES INFORM&Aacute;TICOS
          </strong>
        </p>
        <p>
          El usuario se obliga a no realizar un uso indebido de esta
          p&aacute;gina web mediante la introducci&oacute;n intencionada en la
          misma de virus, troyanos, gusanos, bombas l&oacute;gicas o cualquier
          otro programa o material tecnol&oacute;gicamente perjudicial o
          da&ntilde;ino. No tratar&aacute; de tener acceso no autorizado a esta
          p&aacute;gina web, al servidor en que dicha p&aacute;gina se encuentra
          alojada, ordenador o base de datos relacionada con nuestra
          p&aacute;gina web. Se compromete a no atacar esta p&aacute;gina web a
          trav&eacute;s de un ataque de denegaci&oacute;n de servicio o de un
          ataque de denegaci&oacute;n de servicio distribuido.
        </p>
        <p>
          El incumplimiento de esta cl&aacute;usula podr&iacute;a llevar
          aparejada la comisi&oacute;n de infracciones tipificadas como delito o
          infracci&oacute;n por la normativa aplicable. Informaremos de
          cualquier incumplimiento de dicha normativa a las autoridades
          competentes y cooperaremos con ellas para descubrir la identidad del
          atacante. Asimismo, en caso de incumplimiento de la presente
          cl&aacute;usula, dejar&aacute; inmediatamente de estar autorizado a
          usar esta p&aacute;gina web.
        </p>
        <p>
          No seremos responsables de cualquier da&ntilde;o o p&eacute;rdida
          resultante de un ataque de denegaci&oacute;n de servicio, virus o
          cualquier otro programa o material tecnol&oacute;gicamente perjudicial
          o da&ntilde;ino que pueda afectar a su ordenador, datos o materiales
          como consecuencia del uso de esta p&aacute;gina web o de la descarga
          de contenidos de la misma o a los que la misma pueda redireccionar.
        </p>
        <p>
          Borrajo no responder&aacute; por las interrupciones que se produzcan
          en los servicios el&eacute;ctricos o de telecomunicaciones que impidan
          a los Usuarios utilizar los servicios ofrecidos.
        </p>
        <p>
          <strong>LEY APLICABLE Y JURISDICCI&Oacute;N</strong>
        </p>
        <p>
          Las presentes Condiciones Generales se rigen por la legislaci&oacute;n
          espa&ntilde;ola.
        </p>
        <p>
          En caso de controversia o desavenencia que se derive de la compra de
          productos a trav&eacute;s de la web y/o de las presentes Condiciones
          Generales y para la resoluci&oacute;n de cualesquiera conflictos, las
          partes acuerdan someterse a los Tribunales de Madrid, salvo que por
          derecho corresponda el fuero al domicilio del Usuario.
        </p>
      </>
    );

  if (lang === "en")
    txt = (
      <>
        <p>
          <strong>TITULARIDAD DEL PORTAL</strong>
        </p>
        <p>
          De acuerdo con el establecido en la ley 34/2002 de 11 de julio de
          Servicios de la Sociedad de la Informaci&oacute;n y de Comercio
          Electr&oacute;nico (en adelante LSSICE), se indican los datos de
          informaci&oacute;n general de este sitio web:
        </p>
        <ul>
          <li>
            Titular: Intern. de Comercio y Destiler&iacute;as J.Borrajo S.A (En
            Adelante Borrajo)
          </li>
          <li>
            Domicilio: C/ Francisco Redondo, 10. Talavera de la Reina, Toledo
            (Spain)
          </li>
        </ul>
        <ul>
          <li>
            Contacto:info@<a href="https://destileriasborrajo.net/">net</a>
          </li>
        </ul>
        <ul>
          <li>Tel&eacute;fono de Atenci&oacute;n al Cliente: 925802004</li>
        </ul>
        <p>
          El domicilio a efectos de reclamaciones corresponder&aacute; con el
          indicado como domicilio social de la empresa.
        </p>
        <p>
          Borrajo, es una sociedad an&oacute;nima con C.I.F. A28928885, inscrita
          en el Registro Mercantil de inscripci&oacute;n xxxxxxxxx de Toledo,
          Tomo xxxxxxxxxx, Folio xxxxxxxxxx, H.B. xxxxxxxxxx. Es titular del
          portal&nbsp;www.destileriasjborrajo.net&nbsp;(en adelante el portal,
          la web o la tienda indistintamente) y lo pone a disposici&oacute;n de
          los usuarios de Internet, con el fin de permitir la adquisici&oacute;n
          de los productos comercializados por la compa&ntilde;&iacute;a y/o de
          terceros si fuere el caso.
        </p>
        <p>
          <strong>PROTECCI&Oacute;N DE DATOS</strong>
        </p>
        <p>
          Los datos recogidos a trav&eacute;s de la web, o de los mecanismos
          indicados en ella, ser&aacute;n incorporados en los ficheros
          titularidad de BORRAJO. Puede consultar la Ley de Privacidad
          en&nbsp;https://www.destileriasjborrajo.net/privacidad
        </p>
        <p>
          <strong>Tratamiento de datos</strong>
        </p>
        <p>Sus datos ser&aacute;n utilizados para:</p>
        <ul>
          <li>
            Perfeccionar, cumplir y controlar el correcto cumplimiento del
            contrato y de las obligaciones legales de Borrajo.
          </li>
          <li>
            Gestionar su cuenta de usuario, el acceso y las acciones que se
            realicen como usuario registrado.
          </li>
          <li>
            Enviarle newsletters/boletines de noticias e informaci&oacute;n,
            pudiendo darse de baja en cualquier momento bien a trav&eacute;s del
            propio mail donde se habilitar&aacute; una direcci&oacute;n
            electr&oacute;nica o entrando en &ldquo;Mi cuenta&rdquo; si ya es
            usuario registrado.
          </li>
          <li>
            Mandarle comunicaciones promocionales sobre los productos de Borrajo
            que tengan que ver con su compra o en caso de que as&iacute; nos lo
            haya solicitado de cualquier otro producto u oferta comercial.
          </li>
        </ul>
        <p>
          Los datos requeridos a trav&eacute;s de los formularios o registros
          que tengan un asterisco (*) son obligatorios y sin ellos no
          podr&aacute;n realizarse las gestiones solicitadas por el usuario.
        </p>
        <p>
          <strong>
            Comunicaciones comerciales, promocionales y newsletters
          </strong>
        </p>
        <ul>
          <li>
            Contenido de las comunicaciones: Borrajo podr&aacute; enviarle
            comunicaciones promocionales relacionadas con los productos
            disponibles en nuestra web, nunca lo har&aacute; de terceros salvo
            que le informe previamente y con su consentimiento.
          </li>
          <li>
            Medios: Borrajo le enviar&aacute; dichas comunicaciones por
            cualquier medio (correo postal, telef&oacute;nico, email o cualquier
            otro medio electr&oacute;nico).
          </li>
        </ul>
        <p>
          En relaci&oacute;n a las comunicaciones promocionales por medios
          electr&oacute;nicos, le informaremos en cada una de las mismas,
          c&oacute;mo darle de baja de una forma gratuita y sencilla.
          Atenderemos su solicitud tan pronto como sea posible y, en cualquier
          caso, dentro del plazo legal establecido.
        </p>
        <p>
          <strong>Modificaci&oacute;n de los datos y/o bajas</strong>
        </p>
        <p>
          Podr&aacute; contactar con nosotros en BORRAJO C/ Francisco Redondo,
          10. Talavera de la Reina, Toledo (Spain), indicando en el asunto
          &ndash; &ldquo;RGPD&rdquo; &ndash; para cualquier cuesti&oacute;n
          relacionada con sus datos personales- y podr&aacute; ejercer su
          derecho de acceso, rectificaci&oacute;n, cancelaci&oacute;n,
          limitaci&oacute;n, portabilidad y oposici&oacute;n en cualquier
          momento (sin perjuicio del procedimiento espec&iacute;fico para las
          comunicaciones promocionales con fines publicitarios por medios
          electr&oacute;nicos).
        </p>
        <p>
          <strong>Datos de terceros</strong>
        </p>
        <p>
          Antes de que nos facilite cualquier dato de terceros, debe haberle
          informado y haber obtenido su consentimiento de acuerdo con
          nuestra&nbsp;
          <a href="https://destileriasjborrajo.net/privacy/">
            Pol&iacute;tica de Privacidad
          </a>
          .
        </p>
        <p>
          Borrajo no toma ninguna decisi&oacute;n en relaci&oacute;n a este
          env&iacute;o y s&oacute;lo seguir&aacute; sus instrucciones. El
          env&iacute;o ser&aacute; inmediato, por lo que no ser&aacute; posible
          la posterior correcci&oacute;n de la direcci&oacute;n referida. Debe
          saber que las medidas de seguridad de nuestros sistemas para la
          prestaci&oacute;n de este servicio son de nivel b&aacute;sico.
        </p>
        <p>
          No usaremos o facilitaremos los datos revelados por usted para
          ning&uacute;n otro prop&oacute;sito diferente a la transmisi&oacute;n
          de su mensaje y, una vez finalizado este env&iacute;o solicitado por
          usted, no guardaremos estos datos ni ninguna informaci&oacute;n, salvo
          que la ley as&iacute; lo establezca.
        </p>
        <p>
          <strong>
            ACEPTACI&Oacute;N DE LAS CONDICIONES GENERALES DE USO Y
            CONTRATACI&Oacute;N DEL SITIO WEB
          </strong>
        </p>
        <ul>
          <li>
            A continuaci&oacute;n, se expone el documento contractual que
            regir&aacute; la contrataci&oacute;n de productos y/o servicios a
            trav&eacute;s del sitio web&nbsp;
            <a href="https://destileriasjborrajo.net/">destilerias</a>
            &nbsp;propiedad de Borrajo (en adelante el prestador). La
            aceptaci&oacute;n del presente documento conlleva que el usuario:
          </li>
          <ul>
            <li>
              Ha le&iacute;do, entiende y comprende lo aqu&iacute; expuesto.
            </li>
            <li>Que es una persona con capacidad suficiente para contratar.</li>
            <li>Que asume todas las obligaciones aqu&iacute; dispuestas.</li>
          </ul>
          <li>
            Las presentes condiciones tendr&aacute;n un periodo de validez
            indefinido y ser&aacute;n aplicables a todas las contrataciones
            realizadas a trav&eacute;s del sitio web www.destileriasjborrajo.net
          </li>
          <li>
            Las presentes Condiciones Generales de Uso y Contrataci&oacute;n del
            sitio web de Borrajo, junto con las Condiciones Particulares que
            puedan establecerse, tienen como finalidad poner a
            disposici&oacute;n del usuario informaci&oacute;n necesaria y
            regular las relaciones comerciales que surjan entre Borrajo y los
            usuarios de la web. Tanto la navegaci&oacute;n, como el registro, la
            utilizaci&oacute;n y/o la adquisici&oacute;n de cualquiera de los
            productos de la web, suponen la aceptaci&oacute;n como Usuario, sin
            reservas de ninguna clase, de todas y cada una de las presentes
            Condiciones Generales de Uso y Contrataci&oacute;n que en su caso
            rijan la adquisici&oacute;n de los bienes y/o la prestaci&oacute;n
            de los servicios, as&iacute; como en su caso, de las Condiciones
            Particulares si las hubiere. Borrajo podr&aacute; en todo momento y
            sin previo aviso, modificar las presentes Condiciones Generales de
            Uso y Contrataci&oacute;n y las Condiciones Particulares mediante la
            publicaci&oacute;n de dichas modificaciones en la web para que
            puedan ser conocidas por los Usuarios. Dicha modificaci&oacute;n no
            afectar&aacute; a los bienes o promociones que fueron adquiridos por
            el Usuario previamente a la modificaci&oacute;n.
          </li>
          <li>
            Cookies: Para la utilizaci&oacute;n de nuestro sitio web es
            necesaria la utilizaci&oacute;n de cookies. Las cookies se utilizan
            con la finalidad de ajustar nuestras ofertas a las necesidades de
            los usuarios. Si usted lo desea puede ajustar la
            configuraci&oacute;n de su navegador para ser avisado en pantalla de
            la recepci&oacute;n de cookies y para impedir la instalaci&oacute;n
            de cookies en su disco duro. Por favor consulte las instrucciones y
            manuales de su navegador para ampliar esta informaci&oacute;n, y
            tambi&eacute;n nuestra&nbsp;Pol&iacute;tica de Cookies.
          </li>
        </ul>
        <p>
          Se aconseja al Usuario leer con detenimiento las presentes
          condiciones.
        </p>
        <p>
          <strong>DEFINICIONES</strong>
        </p>
        <p>
          Borrajo: Borrajo SA. Prestador de servicios de la sociedad de la
          informaci&oacute;n de conformidad con la LSSICE y titular de la
          web&nbsp;Borrajo.com
        </p>
        <p>
          Usuario: Persona que acceda a la p&aacute;gina
          web&nbsp;Borrajo.com&nbsp;bien directamente o bien a trav&eacute;s de
          otras p&aacute;ginas o sitios web, y se registre con el fin de
          adquirir productos para su consumo final
        </p>
        <p>
          Condiciones Generales de Contrataci&oacute;n (CGC): son las presentes
          condiciones generales de contrataci&oacute;n que regulan la
          relaci&oacute;n contractual entre Borrajo y el Usuario.
        </p>
        <p>
          <strong>IDENTIDAD DE LAS PARTES</strong>
        </p>
        <p>
          Por una parte, el proveedor de los bienes o servicios contratados por
          el usuario es Borrajo con domicilio social C/ Francisco Redondo, 10.
          Talavera de la Reina, Toledo (Spain)
        </p>
        <p>
          Y de otra parte el usuario registrado en el sitio web mediante un
          nombre de usuario y contrase&ntilde;a.
        </p>
        <p>
          <strong>REGISTRO DE USUARIOS</strong>
        </p>
        <p>
          El Usuario, podr&aacute; registrarse en la web, en el apartado
          &ldquo;Iniciar Sesi&oacute;n&rdquo;. Para ello, deber&aacute;
          introducir los datos personales requeridos como obligatorios. El Alta
          como Usuario de la tienda le proporcionar&aacute; acceso a la web, a
          la adquisici&oacute;n de productos y a la recepci&oacute;n de
          boletines informativos (Newsletter) con informaci&oacute;n de la
          compa&ntilde;&iacute;a y de sus productos.
        </p>
        <p>
          El usuario seleccionar&aacute; un nombre de usuario y una
          contrase&ntilde;a, comprometi&eacute;ndose a hacer uso diligente de
          los mismos, y no ponerlos a disposici&oacute;n de terceros, as&iacute;
          como a comunicar al prestador la p&eacute;rdida o robo de los mismos o
          del posible acceso por un tercero no autorizado, de tal forma que
          &eacute;ste proceda al bloqueo inmediato. El usuario tiene
          responsabilidad plena de su uso y custodia, siendo responsable de la
          veracidad de los datos personales facilitados al prestador.
        </p>
        <p>
          El usuario no podr&aacute; elegir como nombre de usuario palabras que
          tengan como fin el confundir a otros por identificar a &eacute;ste
          como miembro integrante del prestador, as&iacute; como, expresiones
          malsonantes, injuriosas y en general, contrarias a la ley o a las
          exigencias de la moral y buenas costumbres.
        </p>
        <p>
          <strong>INFORMACI&Oacute;N SOBRE PRODUCTOS</strong>
        </p>
        <p>
          Las descripciones de los productos ofrecidos en el portal se realizan
          en base al cat&aacute;logo de productos de Borrajo.
        </p>
        <p>
          Las fotograf&iacute;as, representaciones gr&aacute;ficas o
          iconogr&aacute;ficas y v&iacute;deos relativos a los productos,
          as&iacute; como nombres comerciales, marcas o signos distintivos de
          cualquier clase contenidos en el sitio web tienen por objeto aportar
          la mayor informaci&oacute;n, no obstante, el Usuario ha de tener en
          cuenta que tienen un prop&oacute;sito orientativo y, en consecuencia,
          carecen de car&aacute;cter exhaustivo.
        </p>
        <p>
          Es necesario advertir al usuario que en algunos casos las
          im&aacute;genes ofrecidas con la descripci&oacute;n del producto
          pueden no coincidir exactamente, en estos casos prevalecer&aacute;
          siempre la descripci&oacute;n del producto que se hace en la ficha de
          compra. Estos casos, ser&aacute;n excepcionales ya que la
          intenci&oacute;n de Borrajo es ofrecer siempre una imagen fiel del
          producto ofertado.
        </p>
        <p>
          Asimismo, para garantizar informaci&oacute;n m&aacute;s completa
          tambi&eacute;n le indicaremos la existencia o no de stock del producto
          lo antes posible. En el caso en que el producto no est&eacute;
          disponible despu&eacute;s de haberse realizado la compra, Borrajo
          informar&aacute; al Usuario de la anulaci&oacute;n total o, en su
          caso, parcial del pedido y de la devoluci&oacute;n del precio si
          procede.
        </p>
        <p>
          <strong>PROPIEDAD INTELECTUAL</strong>
        </p>
        <p>
          El usuario reconoce y acepta no realizar ninguna actividad que vaya en
          contra (a t&iacute;tulo enunciativo y no limitativo) del copyright,
          marca registrada, logotipos, textos, fotograf&iacute;as, iconos,
          im&aacute;genes, etc., as&iacute; como el dise&ntilde;o
          gr&aacute;fico, c&oacute;digo fuente, derechos de propiedad
          intelectual, ni sobre los materiales o contenidos que se aportan como
          parte de la p&aacute;gina web, ya que son de Borrajo o de terceros.
        </p>
        <p>
          La informaci&oacute;n p&uacute;blica contenida en la p&aacute;gina web
          de Borrajo referida tanto a la compa&ntilde;&iacute;a Borrajo como a
          las marcas, productos, logotipos, etc. de terceros se encuentran
          protegidas por las disposiciones legales sobre propiedad intelectual e
          industrial, por lo que no se autoriza la copia, transmisi&oacute;n,
          cesi&oacute;n, enajenaci&oacute;n o utilizaci&oacute;n por el Usuario
          ajenas a la finalidad publicitaria de su publicaci&oacute;n virtual,
          salvo que cuenten con el consentimiento expreso de Borrajo, o del
          tercero titular.
        </p>
        <p>
          <strong>
            VIRUS, PIRATER&Iacute;A Y OTROS ATAQUES INFORM&Aacute;TICOS
          </strong>
        </p>
        <p>
          El usuario se obliga a no realizar un uso indebido de esta
          p&aacute;gina web mediante la introducci&oacute;n intencionada en la
          misma de virus, troyanos, gusanos, bombas l&oacute;gicas o cualquier
          otro programa o material tecnol&oacute;gicamente perjudicial o
          da&ntilde;ino. No tratar&aacute; de tener acceso no autorizado a esta
          p&aacute;gina web, al servidor en que dicha p&aacute;gina se encuentra
          alojada, ordenador o base de datos relacionada con nuestra
          p&aacute;gina web. Se compromete a no atacar esta p&aacute;gina web a
          trav&eacute;s de un ataque de denegaci&oacute;n de servicio o de un
          ataque de denegaci&oacute;n de servicio distribuido.
        </p>
        <p>
          El incumplimiento de esta cl&aacute;usula podr&iacute;a llevar
          aparejada la comisi&oacute;n de infracciones tipificadas como delito o
          infracci&oacute;n por la normativa aplicable. Informaremos de
          cualquier incumplimiento de dicha normativa a las autoridades
          competentes y cooperaremos con ellas para descubrir la identidad del
          atacante. Asimismo, en caso de incumplimiento de la presente
          cl&aacute;usula, dejar&aacute; inmediatamente de estar autorizado a
          usar esta p&aacute;gina web.
        </p>
        <p>
          No seremos responsables de cualquier da&ntilde;o o p&eacute;rdida
          resultante de un ataque de denegaci&oacute;n de servicio, virus o
          cualquier otro programa o material tecnol&oacute;gicamente perjudicial
          o da&ntilde;ino que pueda afectar a su ordenador, datos o materiales
          como consecuencia del uso de esta p&aacute;gina web o de la descarga
          de contenidos de la misma o a los que la misma pueda redireccionar.
        </p>
        <p>
          Borrajo no responder&aacute; por las interrupciones que se produzcan
          en los servicios el&eacute;ctricos o de telecomunicaciones que impidan
          a los Usuarios utilizar los servicios ofrecidos.
        </p>
        <p>
          <strong>LEY APLICABLE Y JURISDICCI&Oacute;N</strong>
        </p>
        <p>
          Las presentes Condiciones Generales se rigen por la legislaci&oacute;n
          espa&ntilde;ola.
        </p>
        <p>
          En caso de controversia o desavenencia que se derive de la compra de
          productos a trav&eacute;s de la web y/o de las presentes Condiciones
          Generales y para la resoluci&oacute;n de cualesquiera conflictos, las
          partes acuerdan someterse a los Tribunales de Madrid, salvo que por
          derecho corresponda el fuero al domicilio del Usuario.
        </p>
      </>
    );
  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "Política de privacidad" }) || ""}
        description={
          intl.formatMessage({ id: "POLITICAPRIVACIDAD.DESCRIPTIONSEO" }) || ""
        }
      />
      <div className="container-fluid px-3 px-lg-5 pb-5 secondary-pages">
        <div className="row">
          <div className="col-12">{txt}</div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(PoliticaPrivacidad);

import { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { getProducts } from "../api/products";
import { getBrands } from "../api/brands";
import { getCategories } from "../api/categories";
import { getProductTypes } from "../api/productypes";
import { useLang } from "../translations/i18n";
import { SERVER_URL } from "../utils";
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import Skeleton from "react-loading-skeleton";
import CustomHelmet from "../components/seo/CustomHelmet";
import "react-loading-skeleton/dist/skeleton.css";

const formatUrlString = (str, url = null) => {
  if (url)
    return encodeURIComponent(
      str
        ?.normalize("NFD")
        ?.replace(/[\u0300-\u036f]/g, "")
        ?.toLowerCase()
        ?.split(" ")
        ?.join("-")
    );
  else
    return str
      ?.normalize("NFD")
      ?.replace(/[\u0300-\u036f]/g, "")
      ?.toLowerCase();
};

function searchArrayBySimilarity(
  arr,
  searchQuery,
  lang,
  isBrandSearch = false
) {
  const decodedSearchQuery = decodeURIComponent(searchQuery);
  const queryWords = decodedSearchQuery?.toLowerCase().split(" ");

  return arr.filter((obj) => {
    let objName = isBrandSearch
      ? obj.fullName?.toLowerCase()
      : obj.fullName?.[lang]?.toLowerCase();
    let matches = queryWords.reduce(
      (count, word) => (objName?.includes(word) ? count + 1 : count),
      0
    );
    return matches === queryWords.length;
  });
}
function normalizeBrandName(name) {
  return name.trim().toLowerCase();
}
function ProductListPage({ intl }) {
  const [results, setResults] = useState([]);
  const [brands, setBrands] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [skeleton, setSkeleton] = useState(true);
  const [relatedBrands, setRelatedBrands] = useState([]);
  const location = useLocation();
  const searchQuery = location.search.split("=")[1];
  const lang = useLang();

  useEffect(() => {
    window.scrollTo(0, 0);

    Promise.all([getCategories(), getProductTypes(), getBrands()])
      .then(([categoryData, productTypeData, fetchedBrands]) => {
        setCategories(categoryData.data);
        setProductTypes(productTypeData.data);
        setBrands(fetchedBrands.data);

        getProducts().then((res) => {
          if (res.status === 200) {
            const productResults = searchArrayBySimilarity(
              res.data,
              searchQuery,
              lang
            );
            const brandResults = searchArrayBySimilarity(
              fetchedBrands.data,
              searchQuery,
              lang,
              true
            );
            console.log(productResults, brandResults);

            setResults(productResults);
            const relatedBrandIds = productResults.map(
              (product) => product.brand
            );
            const uniqueRelatedBrands = fetchedBrands.data.filter((brand) =>
              relatedBrandIds.includes(brand._id)
            );

            const formattedBrandResults = brandResults.map((brand) => ({
              ...brand,
              fullName: brand.fullName.trim(),
            }));
            const formattedUniqueRelatedBrands = uniqueRelatedBrands.map(
              (brand) => ({
                ...brand,
                fullName: brand.fullName.trim(),
              })
            );

            const finalBrands = [
              ...formattedBrandResults,
              ...formattedUniqueRelatedBrands,
            ];
            const uniqueBrands = finalBrands.reduce((unique, brand) => {
              const existingBrand = unique.find(
                (b) => b.fullName === brand.fullName
              );
              if (!existingBrand) {
                unique.push(brand);
              }
              return unique;
            }, []);
            setRelatedBrands(uniqueBrands);
            setSkeleton(false);
          }
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [searchQuery]);

  const constructProductURL = (product, lang) => {
    const productType = productTypes?.find((pt) => pt._id === product?.type);
    const brand = brands?.find((b) => b._id === product?.brand);
    const category = categories?.find(
      (cat) => cat._id === productType?.category
    )?.name[lang];

    let basePath = lang === "en" ? "/en/products" : "/productos";
    if (category) basePath += `/${formatUrlString(category, "url")}`;
    if (productType)
      basePath += `/${formatUrlString(productType.fullName[lang], "url")}`;
    if (brand) basePath += `/${formatUrlString(brand.fullName, "url")}`;
    if (product)
      basePath += `/${formatUrlString(product.fullName[lang], "url")}`;
    return basePath;
  };

  return skeleton ? (
    <div className="skeleton-grid container mt-5">
      {Array.from({ length: 5 }).map((_, index) => (
        <Skeleton key={index} width={200} height={200} />
      ))}
    </div>
  ) : (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "RESULTSPAGE.RESULTADOS" }) || ""}
      />
      <div className="container mt-5">
        <h2>
          <FormattedMessage id="RESULTSPAGE.RESULTADOS" />
        </h2>
        <p>
          {results?.length ? results.length : 0}{" "}
          <FormattedMessage id="RESULTSPAGE.PRODUCTOSRELACIONADOS" />
        </p>
        <div className="row">
          {results?.map((product) => (
            <div className="col-md-3 col-sm-6 mb-4 mr-auto" key={product._id}>
              <Link
                to={constructProductURL(product, lang)}
                key={product._id}
                className="text-decoration-none"
              >
                <div className="results-card">
                  <img
                    src={`${SERVER_URL}/${product.footerImage}`}
                    alt={product.fullName[lang]}
                    className="card-img-product"
                  />
                  <div className="card-body">
                    <h5 className="card-title">{product.fullName[lang]}</h5>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
        <h2>
          <FormattedMessage id="RESULTSPAGE.MARCASRELACIONADAS" />
        </h2>
        <p>
          {relatedBrands?.length ? relatedBrands.length : 0}{" "}
          <FormattedMessage id="RESULTSPAGE.MARCAS" />
        </p>
        <div className="row">
          {relatedBrands?.map((brand) => (
            <div className="col-md-3 col-sm-6 mb-4" key={brand._id}>
              <div className="results-card">
                <img
                  src={`${SERVER_URL}/${brand.logo}`}
                  alt={brand.fullName}
                  className="card-img-brand"
                />
                <div className="card-body">
                  <h5 className="card-title">{brand.fullName}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default injectIntl(ProductListPage);

import React, { useState, useEffect } from "react";
import { getCategories } from "../../api/categories";
import { useLang } from "../../translations/i18n";
import { SERVER_URL } from "../../utils";

export default function Category({ catName }) {
  const [category, setCategory] = useState(null);
  const lang = useLang();
  useEffect(() => {
    getCategories()
      .then((res) => {
        if (res.status === 200)
          setCategory(
            res.data.find(
              (cat) =>
                cat.name[lang]
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "") ===
                catName.split("-").join(" ")
            )
          );
      })
      .catch((e) => {
        console.error(e);
      });
  }, [catName]);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isVideo =
    category?.image && /\.(mp4|avi|mov|wmv)$/i.test(category.image);
  const mediaStyles = {
    width: "100%",
    minHeight: "100vh",
    objectFit: "cover",
    backgroundImage: `url(${SERVER_URL}/${category?.image})`,
    backgroundSize: "cover",
    backgroundPosition: isMobile ? "center" : "left-center",
    backgroundRepeat: "no-repeat",
  };

  // Check if it's a mobile device

  const mediaElement = isVideo ? (
    <video
      src={`${SERVER_URL}/${category?.image}`}
      autoPlay
      loop
      style={mediaStyles}
    />
  ) : (
    <div style={mediaStyles} />
  );

  return mediaElement;
}

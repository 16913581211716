import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import { getCategories } from "../../api/categories";
import { getProductTypes } from "../../api/productypes";
import { getProducts } from "../../api/products";
import { getBrands } from "../../api/brands";
import { useLang } from "../../translations/i18n";
import { FaAngleUp } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";
import { RUTAS } from "../../constants/routes.constants";
import { act } from "react-dom/test-utils";

const formatUrlString = (str, url = null) => {
  if (url)
    return encodeURIComponent(
      str
        ?.normalize("NFD")
        ?.replace(/[\u0300-\u036f]/g, "")
        ?.toLowerCase()
        ?.split(" ")
        ?.join("-")
    );
  else
    return str
      ?.normalize("NFD")
      ?.replace(/[\u0300-\u036f]/g, "")
      ?.toLowerCase();
};

function generateUrl(routeKey, params = {}, lang = "es") {
  const baseRoutes = RUTAS[routeKey][lang];
  const path = Array.isArray(baseRoutes)
    ? baseRoutes[Object.keys(params).length - 1]
    : baseRoutes;
  let finalPath = path;

  for (const paramKey in params) {
    if (params[paramKey]) {
      finalPath = finalPath.replace(`:${paramKey}`, params[paramKey]);
    }
  }
  // finalPath = finalPath.replace(/-$/, "");
  return finalPath;
}

function LateralNav({ intl }) {
  const lang = useLang();
  const { categoria, producttype, brand, product } = useParams();
  const [categories, setCategories] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [products, setProducts] = useState([]);
  const [organizedProducts, setOrganizedProducts] = useState({});
  const [brands, setBrands] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeProductType, setActiveProductType] = useState(null);
  const [activebrand, setActiveBrand] = useState(null);
  const [activeProduct, setActiveProduct] = useState(null);
  const categoriaFormateada = categoria.split("-").join(" ");
  const renderedBrands = new Set();
  const [brandsEspeciales, setBrandsEspeciales] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [productPath, setProductPath] = useState(
    lang == "en" ? "en/products" : "productos"
  );
  const [nuestrasmarcas, setNuestrasmarcas] = useState(
    false
    // categoria === "nuestras-marcas" ? true : false
  );
  const location = useLocation();

  //Menu mobile
  const [menuMobile, setMenuMobile] = useState(true);
  const [selectedPath, setSelectedPath] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log("CATEGORY: ", categoria);
    // console.log("ACTIVE CATEGORY: ", activeCategory);
    // console.log("PRODUCT TYPE: ", producttype);
    // console.log("ACTIVE PRODUCT TYPE: ", activeProductType);
    // console.log("BRAND: ", brand);
    // console.log("ACTIVE BRAND: ", activebrand);
    // console.log("PRODUCT: ", product);
    // console.log("ACTIVE PRODUCT: ", activeProduct);
    // console.log("PRODUCT PATH: ", productPath);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesRes = await getCategories();
        const productTypesRes = await getProductTypes();
        const productsRes = await getProducts();
        const brandsRes = await getBrands();

        if (categoriesRes.status === 200) {
          const sortedCategories = sortData(categoriesRes.data);
          setCategories(sortedCategories);
          setActiveCategory(
            findActiveItem(sortedCategories, categoriaFormateada, lang, "name")
          );
        }

        if (productTypesRes.status === 200) {
          const sortedProductTypes = sortData(productTypesRes.data);
          setProductTypes(sortedProductTypes);
          setActiveProductType(
            findActiveItem(sortedProductTypes, producttype, lang, "fullName")
          );
        }

        if (productsRes.status === 200) {
          setProducts(
            productsRes.data.sort((a, b) =>
              a.fullName[lang] > b.fullName[lang] ? 1 : -1
            )
          );
          setActiveProduct(
            findActiveItem(productsRes.data, product, lang, "fullName")
          );
        }

        if (brandsRes.status === 200) {
          const sortedBrands = sortData(brandsRes.data);
          setBrands(sortedBrands);
          // console.log(sortedBrands);
          setBrandsEspeciales(
            brandsRes.data.filter((b) => b.mundotequila === true)
          );
          setActiveBrand(findActiveItem(sortedBrands, brand, null, "fullName"));
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [categoria, producttype, brand, product]);

  // Helper functions
  const sortData = (data) => data.sort((a, b) => a.order - b.order);

  const findActiveItem = (data, urlParam, lang, property) => {
    const formattedParam = urlParam?.split("-").join(" ");
    return data.find((item) => {
      if (lang && typeof item[property] === "object") {
        return formatUrlString(item[property][lang]) === formattedParam;
      } else {
        return formatUrlString(item[property]) === formattedParam;
      }
    })?._id;
  };

  useEffect(() => {
    setActiveCategory(
      findActiveItem(categories, categoriaFormateada, lang, "name")
    );
    setActiveProductType(
      findActiveItem(productTypes, producttype, lang, "fullName")
    );
    setActiveProduct(findActiveItem(products, product, lang, "fullName"));
    setActiveBrand(findActiveItem(brands, brand, null, "fullName"));
  }, [categories, productTypes, products, brands]);

  const toggleCategory = (categoryId) => {
    setClicked(true);
    if (activeCategory === categoryId) {
      setActiveCategory(null);
    } else {
      setActiveCategory(categoryId);
    }
    setActiveProductType(null);
    setActiveBrand(null);
    setActiveProduct(null);
  };

  const toggleProductType = (productTypeId) => {
    setClicked(true);
    if (activeProductType === productTypeId) {
      setActiveProductType(null);
    } else {
      setActiveProductType(productTypeId);
    }
    setActiveBrand(null);
    setActiveProduct(null);
  };

  const toggleBrand = (brandId) => {
    setClicked(true);
    if (activebrand === brandId) {
      setActiveBrand(null);
    } else {
      setActiveBrand(brandId);
    }
    setActiveProduct(null);
  };

  const toggleProduct = (productId) => {
    setClicked(true);
    if (activeProduct === productId) {
      setActiveProduct(null);
    } else {
      setActiveProduct(productId);
    }
  };

  const toggleMenuMobile = () => {
    setMenuMobile(!menuMobile);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 600) {
        setMenuMobile(true);
      } else {
        setMenuMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const newPath = [];
    if (activeCategory) {
      const c = categories.find((c) => c._id === activeCategory);
      if (c) newPath.push(c.name[lang]);
    }
    if (activeProductType) {
      const p = productTypes.find((pt) => pt._id === activeProductType);
      if (p) newPath.push(p.fullName[lang]);
    }
    if (activebrand) {
      const b = brands.find((b) => b._id === activebrand);
      if (b) newPath.push(b.fullName);
    }
    if (activeProduct) {
      const pr = products.find((p) => p._id === activeProduct);
      if (pr) newPath.push(pr.fullName[lang]);
    }
    //if newPath includes "nuestras-marcas or our-brands llamar a setToggleMobile()"
    if (
      location.pathname.includes("nuestras-marcas") ||
      location.pathname.includes("our-brands")
    ) {
      // toggleMenuMobile();
      newPath.push(lang === "en" ? "Our Brands" : "Nuestras Marcas");
    }
    setSelectedPath(newPath);
  }, [activeCategory, activeProductType, activebrand, activeProduct]);

  useEffect(() => {
    const organizeProductsByBrand = () => {
      const organizedProducts = {};
      products.forEach((product) => {
        const brand = brands.find((brand) => brand._id === product.brand);
        if (brand) {
          if (!organizedProducts[brand._id]) {
            organizedProducts[brand._id] = {
              brandname: brand.fullName,
              products: [],
              order: brand.order,
            };
          }
          organizedProducts[brand._id].products.push(product);
        }
      });
      // Convert the object to an array of objects
      const organizedProductsArray = Object.entries(organizedProducts).map(
        ([brandId, data]) => ({
          [brandId]: {
            ...data,
          },
        })
      );
      // Sort the array by brand order
      organizedProductsArray.sort(
        (a, b) => a[Object.keys(a)[0]].order - b[Object.keys(b)[0]].order
      );
      return organizedProductsArray;
    };
    const organizedProducts = organizeProductsByBrand();
    setOrganizedProducts(organizedProducts);
  }, [products, brands]);

  // useEffect(() => {
  //   console.log("Active Category: ", activeCategory);
  //   console.log("Active Product Type: ", activeProductType);
  //   console.log("Active Brand: ", activebrand);
  //   console.log("Active Product: ", activeProduct);
  // }, [activeCategory, activeProductType, activebrand, activeProduct]);

  return (
    <div className="col-12 col-lg-4 bggrey barlowregular p-3 p-lg-4 f22 position-relative">
      <div
        className="closeButton d-sm-block d-md-none"
        onClick={() => toggleMenuMobile()}
      >
        {menuMobile ? <FaAngleUp /> : <FaAngleDown />}
      </div>
      <div className={`menu ${menuMobile ? "" : "menu-closed"} `}>
        <div
          onMouseOver={(e) => e.target.classList.add("link-hovered")}
          onMouseOut={(e) => e.target.classList.remove("link-hovered")}
        >
          <Link
            to={`/${lang === "en" ? "en/products" : "productos"}/${
              lang === "en" ? "our-brands" : "nuestras-marcas"
            }`}
            className={`py-0 py-lg-2 text-decoration-none supertitle ${
              nuestrasmarcas ? "barlowbold brown" : "barlow text-dark"
            }`}
            style={{ cursor: "pointer" }}
            onClick={() => setNuestrasmarcas(!nuestrasmarcas)}
          >
            <span style={{ color: "#000" }}>
              {intl.formatMessage({ id: "PRODUCTS.NUESTRASMARCAS" }) || ""}
            </span>
          </Link>
        </div>
        <div className="supertitle">
          {intl.formatMessage({ id: "PRODUCTS.CATEGORIAS" }) || ""}
        </div>
        {categories
          .filter(
            (c) => !c.categoriamundotequila && !c.categoriamundococteleria
          )
          .map((category) => (
            <div
              key={category._id}
              onMouseOver={(e) => e.target.classList.add("link-hovered")}
              onMouseOut={(e) => e.target.classList.remove("link-hovered")}
            >
              <Link
                to={generateUrl(
                  "SEARCHPRODUCT",
                  { categoria: formatUrlString(category.name[lang], "url") },
                  lang
                )}
                className={`px-2 py-2 text-decoration-none ${
                  activeCategory === category._id
                    ? "barlowbold brown"
                    : "barlow text-dark"
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => toggleCategory(category._id)}
                onMouseOver={(e) => e.target.classList.add("link-hovered")}
                onMouseOut={(e) => e.target.classList.remove("link-hovered")}
              >
                <span>{category.name[lang].toUpperCase()}</span>
              </Link>
              {/*********** ProductTypes */}
              {activeCategory === category._id &&
                productTypes
                  .filter(
                    (productType) => productType.category === category._id
                  )
                  .map((productType) => (
                    <div
                      key={productType._id}
                      className="ml-3"
                      onMouseOver={(e) =>
                        e.target.classList.add("link-hovered")
                      }
                      onMouseOut={(e) =>
                        e.target.classList.remove("link-hovered")
                      }
                      style={{ cursor: "pointer", textDecoration: "none" }}
                    >
                      <Link
                        to={`/${productPath}/${
                          category.name[lang]
                            ? formatUrlString(category.name[lang], "url")
                            : ""
                        }/${
                          productType?.fullName?.[lang]
                            ? formatUrlString(productType.fullName[lang], "url")
                            : ""
                        }`}
                        className={`py-1 px-3 f18 text-decoration-none ${
                          activeProductType === productType._id
                            ? "barlowbold brown"
                            : "barlow text-dark"
                        }`}
                        onClick={() => toggleProductType(productType._id)}
                      >
                        <span className="text-decoration-none">
                          {productType.fullName[lang]}
                        </span>
                      </Link>
                      {activeProductType === productType._id && (
                        <ul className="py-1 my-0 px-5">
                          {/*** Brands */}
                          {products
                            .filter(
                              (product) => product.type === productType._id
                            )
                            .map((filteredProduct) => {
                              const brandData = organizedProducts.find(
                                (brandData) =>
                                  brandData[
                                    Object.keys(brandData)[0]
                                  ].products.some(
                                    (product) =>
                                      product._id === filteredProduct._id
                                  )
                              );

                              if (brandData) {
                                const brandId = Object.keys(brandData)[0];
                                const brandname = brandData[brandId].brandname;
                                if (!renderedBrands.has(brandId)) {
                                  renderedBrands.add(brandId);
                                  return (
                                    <section
                                      className="barlowbold f15"
                                      key={brandId}
                                    >
                                      <div
                                        onMouseOver={(e) =>
                                          e.target.classList.add("link-hovered")
                                        }
                                        onMouseOut={(e) =>
                                          e.target.classList.remove(
                                            "link-hovered"
                                          )
                                        }
                                        style={{
                                          cursor: "pointer",
                                          textDecoration: "none",
                                        }}
                                      >
                                        {category && (
                                          <Link
                                            to={`/${productPath}/${formatUrlString(
                                              category?.name?.[lang],
                                              "url"
                                            )}/${formatUrlString(
                                              productType?.fullName?.[lang],
                                              "url"
                                            )}/${formatUrlString(
                                              brandname,
                                              "url"
                                            )}`}
                                            key={brandId}
                                            className={`py-0 py-lg-1 f15 text-decoration-none ${
                                              activebrand === brandId
                                                ? "barlowbold brown"
                                                : "barlowregular text-dark"
                                            }`}
                                            onClick={() => toggleBrand(brandId)}
                                          >
                                            <strong>{brandname}</strong>
                                          </Link>
                                        )}
                                      </div>
                                      {/*********** Products ***********/}
                                      {activebrand === brandId &&
                                        brandData[brandId].products
                                          .filter(
                                            (product) =>
                                              product.type === activeProductType
                                          )
                                          .map((product) => (
                                            <div
                                              style={{
                                                cursor: "pointer",
                                                textDecoration: "none",
                                              }}
                                            >
                                              {category && (
                                                <Link
                                                  to={`/${productPath}/${formatUrlString(
                                                    category?.name?.[lang],
                                                    "url"
                                                  )}/${formatUrlString(
                                                    productType?.fullName?.[
                                                      lang
                                                    ],
                                                    "url"
                                                  )}/${formatUrlString(
                                                    brandname,
                                                    "url"
                                                  )}/${formatUrlString(
                                                    product?.fullName?.[lang],
                                                    "url"
                                                  )}`}
                                                  key={product._id}
                                                  className={`py-0 py-lg-1 px-3 f15 ${
                                                    activeProduct ===
                                                    product._id
                                                      ? "barlowbold brown"
                                                      : "barlowregular text-dark"
                                                  }`}
                                                  onClick={() => {
                                                    toggleProduct(product._id);
                                                    if (
                                                      window.innerWidth <= 768
                                                    ) {
                                                      toggleMenuMobile();
                                                    }
                                                  }}
                                                >
                                                  <span>
                                                    {product.fullName[lang]}
                                                  </span>
                                                </Link>
                                              )}
                                            </div>
                                          ))}
                                    </section>
                                  );
                                }
                              }
                              return null;
                            })}
                        </ul>
                      )}
                    </div>
                  ))}
            </div>
          ))}
        <div className="supertitle">
          {intl.formatMessage({ id: "PRODUCTS.NUESTROSMUNDOS" }) || ""}
        </div>
        {categories
          .filter((c) => c.categoriamundotequila || c.categoriamundococteleria)
          .map((category) =>
            category.categoriamundotequila ? (
              <div
                key={category._id}
                onMouseOver={(e) => e.target.classList.add("link-hovered")}
                onMouseOut={(e) => e.target.classList.remove("link-hovered")}
              >
                <Link
                  to={generateUrl(
                    "SEARCHPRODUCT",
                    { categoria: formatUrlString(category.name[lang], "url") },
                    lang
                  )}
                  className={`px-2 py-2 text-decoration-none ${
                    activeCategory === category._id
                      ? "barlowbold brown"
                      : "barlow text-dark"
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => toggleCategory(category._id)}
                  onMouseOver={(e) => e.target.classList.add("link-hovered")}
                  onMouseOut={(e) => e.target.classList.remove("link-hovered")}
                >
                  <span>{category.name[lang].toUpperCase()}</span>
                </Link>
                {activeCategory === category._id &&
                  category.categoriamundotequila &&
                  brandsEspeciales.map((brand) => (
                    <div className="px-3">
                      <Link
                        to={`/${productPath}/${formatUrlString(
                          categories.find(
                            (c) => c.name?.en === "Alcoholic drinks"
                          )?.name[lang],
                          "url"
                        )}/tequila/${formatUrlString(brand.fullName, "url")}`}
                        key={brand._id}
                        className={`py-0 py-lg-1 f15 text-decoration-none ${
                          activebrand === brand._id
                            ? "barlowbold brown"
                            : "barlowregular text-dark"
                        }`}
                        onClick={() => {
                          toggleCategory(
                            categories.find(
                              (c) => c.name?.en === "Alcoholic drinks"
                            )._id
                          );
                          toggleProductType(
                            productTypes.find(
                              (pt) => pt.fullName?.en === "Tequila"
                            )._id
                          );
                          toggleBrand(brand._id);
                        }}
                      >
                        <strong>{brand.fullName}</strong>
                      </Link>
                    </div>
                  ))}
              </div>
            ) : (
              <div
                key={category._id}
                onMouseOver={(e) => e.target.classList.add("link-hovered")}
                onMouseOut={(e) => e.target.classList.remove("link-hovered")}
              >
                <Link
                  to={generateUrl(
                    "SEARCHPRODUCT",
                    { categoria: formatUrlString(category.name[lang], "url") },
                    lang
                  )}
                  className={`px-2 py-2 text-decoration-none ${
                    activeCategory === category._id
                      ? "barlowbold brown"
                      : "barlow text-dark"
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => toggleCategory(category._id)}
                  onMouseOver={(e) => e.target.classList.add("link-hovered")}
                  onMouseOut={(e) => e.target.classList.remove("link-hovered")}
                >
                  <span>{category.name[lang].toUpperCase()}</span>
                </Link>
                {activeCategory === category._id &&
                  category.categoriamundococteleria &&
                  productTypes
                    .filter(
                      (productType) => productType.category === category._id
                    )
                    .map((productType) => {
                      // console.log(productType);

                      return (
                        <div
                          key={productType._id}
                          className="ml-3"
                          onMouseOver={(e) =>
                            e.target.classList.add("link-hovered")
                          }
                          onMouseOut={(e) =>
                            e.target.classList.remove("link-hovered")
                          }
                          style={{ cursor: "pointer", textDecoration: "none" }}
                        >
                          <Link
                            to={generateUrl(
                              "SEARCHPRODUCT",
                              {
                                categoria: formatUrlString(
                                  category.name[lang],
                                  "url"
                                ),
                                producttype: formatUrlString(
                                  productType.fullName[lang],
                                  "url"
                                ),
                              },
                              lang
                            )}
                            className={`py-1 px-3 f18 text-decoration-none ${
                              activeProductType === productType._id
                                ? "barlowbold brown"
                                : "barlow text-dark"
                            }`}
                            onClick={() => toggleProductType(productType._id)}
                          >
                            <span className="text-decoration-none">
                              {productType.fullName[lang]}
                            </span>
                          </Link>
                          {activeProductType === productType._id && (
                            <ul className="py-1 my-0 px-5">
                              {/*********** Brands */}
                              {products
                                .filter(
                                  (product) =>
                                    product.type2 === productType._id ||
                                    product.type === productType._id
                                )
                                .map((filteredProduct) => {
                                  const brandData = organizedProducts.find(
                                    (brandD) =>
                                      brandD[
                                        Object.keys(brandD)[0]
                                      ].products.some(
                                        (product) =>
                                          product._id === filteredProduct._id
                                      )
                                  );
                                  if (brandData) {
                                    const brandId = Object.keys(brandData)[0];
                                    const brandname =
                                      brandData[brandId].brandname;
                                    // console.log(
                                    //   "BRANDNAME: ",
                                    //   brandname,
                                    //   brandId
                                    // );
                                    if (!renderedBrands.has(brandId)) {
                                      renderedBrands.add(brandId);
                                      return (
                                        <section
                                          className="barlowbold f15"
                                          key={brandId}
                                        >
                                          <div
                                            onMouseOver={(e) =>
                                              e.target.classList.add(
                                                "link-hovered"
                                              )
                                            }
                                            onMouseOut={(e) =>
                                              e.target.classList.remove(
                                                "link-hovered"
                                              )
                                            }
                                            style={{
                                              cursor: "pointer",
                                              textDecoration: "none",
                                            }}
                                          >
                                            <Link
                                              to={generateUrl(
                                                "SEARCHPRODUCT",
                                                {
                                                  categoria: formatUrlString(
                                                    category.name[lang],
                                                    "url"
                                                  ),
                                                  producttype: formatUrlString(
                                                    productType.fullName[lang],
                                                    "url"
                                                  ),
                                                  brand: formatUrlString(
                                                    brandname,
                                                    "url"
                                                  ),
                                                },
                                                lang
                                              )}
                                              key={brandId}
                                              className={`py-0 py-lg-1 f15 text-decoration-none ${
                                                activebrand === brandId
                                                  ? "barlowbold brown"
                                                  : "barlowregular text-dark"
                                              }`}
                                              onClick={() =>
                                                toggleBrand(brandId)
                                              }
                                            >
                                              <strong>{brandname}</strong>
                                            </Link>
                                          </div>
                                          {/*********** Products ***********/}
                                          {activebrand === brandId &&
                                            brandData[brandId].products
                                              .filter(
                                                (product) =>
                                                  product.type2 ===
                                                    activeProductType ||
                                                  product.type ===
                                                    activeProductType
                                              )
                                              .map((product) => {
                                                // console.log(
                                                //   "PRODUCT: ",
                                                //   product
                                                // );

                                                return (
                                                  <div
                                                    style={{
                                                      cursor: "pointer",
                                                      textDecoration: "none",
                                                    }}
                                                  >
                                                    {category && (
                                                      <Link
                                                        to={generateUrl(
                                                          "SEARCHPRODUCT",
                                                          {
                                                            categoria:
                                                              formatUrlString(
                                                                category.name[
                                                                  lang
                                                                ],
                                                                "url"
                                                              ),
                                                            producttype:
                                                              formatUrlString(
                                                                productType
                                                                  .fullName[
                                                                  lang
                                                                ],
                                                                "url"
                                                              ),
                                                            brand:
                                                              formatUrlString(
                                                                brandname,
                                                                "url"
                                                              ),
                                                            product:
                                                              formatUrlString(
                                                                product
                                                                  .fullName[
                                                                  lang
                                                                ],
                                                                "url"
                                                              ),
                                                          },
                                                          lang
                                                        )}
                                                        key={product._id}
                                                        className={`py-0 py-lg-1 px-3 f15 ${
                                                          activeProduct ===
                                                          product._id
                                                            ? "barlowbold brown"
                                                            : "barlowregular text-dark"
                                                        }`}
                                                        onClick={() => {
                                                          toggleProduct(
                                                            product._id
                                                          );
                                                          if (
                                                            window.innerWidth <=
                                                            768
                                                          ) {
                                                            toggleMenuMobile();
                                                          }
                                                        }}
                                                      >
                                                        <span>
                                                          {
                                                            product.fullName[
                                                              lang
                                                            ]
                                                          }
                                                        </span>
                                                      </Link>
                                                    )}
                                                  </div>
                                                );
                                              })}
                                        </section>
                                      );
                                    }
                                  }
                                  return null;
                                })}
                            </ul>
                          )}
                        </div>
                      );
                    })}
              </div>
            )
          )}
      </div>
      {!menuMobile && (
        <div className="selectedPath barlowbold brown d-sm-block d-md-none">
          {selectedPath.map((selection, index) => (
            <span key={index}>
              {selection}
              {index < selectedPath.length - 1 && " > "}
            </span>
          ))}
        </div>
      )}
    </div>
  );
}

export default injectIntl(LateralNav);

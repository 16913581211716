import React, { useEffect, useState } from "react";

const Linkedin = () => {
  return (
    <div>
      <iframe
        src="https://widgets.sociablekit.com/linkedin-page-posts/iframe/237187"
        frameBorder="0"
        width="100%"
        height="500"
      ></iframe>
    </div>
  );
};

export default Linkedin;

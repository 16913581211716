import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../utils";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";
import { RUTAS } from "../constants/routes.constants";
import TranslatedLink from "../components/navigation/TranslatedLink";
import clsx from "clsx";
import videoheaderwebm from "../assets/videoheader.webm";
import headerhomevid from "../assets/headerhome.webm";
import homevideo from "../assets/home-video.webm";
import headerhome from "../assets/Header_home_en.webm";
import icolinkedin from "../assets/ico-linkedin.png";
import icoinstagram from "../assets/ico-instagram.png";
import Slideshow from "../components/Slider";
import minimex from "../assets/insta-mini-mex.png";
import minisanz from "../assets/insta-mini-sanz.png";
import miniguey from "../assets/insta-mini-guey.png";
import Instagram from "../components/feeds/Instagram";
import Linkedin from "../components/feeds/Linkedin";

function getEveryNth(arr, nth) {
  const result = [];

  for (let i = 0; i < arr.length; i += nth) result.push(arr.slice(i, i + nth));

  return result;
}

function Home({ intl }) {
  const [data, setData] = useState([]);
  const btn1 = useRef(null);
  const btn2 = useRef(null);
  const btn3 = useRef(null);
  const btn4 = useRef(null);
  const btn5 = useRef(null);
  const text1 = useRef(null);
  const text2 = useRef(null);
  const text3 = useRef(null);
  const text4 = useRef(null);
  const text5 = useRef(null);
  const text6 = useRef(null);
  const text7 = useRef(null);
  const text8 = useRef(null);

  const lang = useLang();

  useEffect(() => {
    window.scrollTo(0, 0);
    const observer2 = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("txt-animation");
          } else {
            entry.target.classList.remove("txt-animation");
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      }
    );

    [text1, text2, text3, text4, text5, text6, text7, text8].forEach((text) => {
      if (text.current) {
        observer2.observe(text.current);
      }
    });

    return () => {
      [text1, text2, text3, text4, text5, text6, text7, text8].forEach(
        (text) => {
          if (text.current) {
            observer2.unobserve(text.current);
          }
        }
      );
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animation");
          } else {
            entry.target.classList.remove("animation");
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      }
    );

    [btn1, btn2, btn3, btn4, btn5].forEach((btn) => {
      if (btn.current) {
        observer.observe(btn.current);
      }
    });

    return () => {
      [btn1, btn2, btn3, btn4, btn5].forEach((btn) => {
        if (btn.current) {
          observer.unobserve(btn.current);
        }
      });
    };
  }, []);

  let count = 0;

  const formatUrlString = (str) => {
    const withoutAccents = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return withoutAccents.toLowerCase().replace(/\s+/g, "-");
  };

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "HOME.TITLESEO" }) || ""}
        description={intl.formatMessage({ id: "HOME.DESCRIPTIONSEO" }) || ""}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            {lang === "es" ? (
              <video loop autoPlay muted playsInline className="video-element">
                <source src={headerhomevid} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <video loop autoPlay muted playsInline className="video-element">
                <source src={headerhome} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 py-5 barlowregular">
            <h1 className="h1_50">
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "HOME.TEXT1" }),
                }}
                ref={text1}
              />
            </h1>
            <h2 className="h1_40">
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "HOME.TEXT2" }),
                }}
                ref={text2}
              />
            </h2>
            <div className="my-5 txt-main">
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "HOME.TEXT3" }),
                }}
                ref={text3}
              />
            </div>
            <section className="d-flex justify-content-center justify-content-lg-start">
              <TranslatedLink to={RUTAS.LADESTILERIA}>
                <button className="my-3" ref={btn1}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: "HOME.MOREINFO" }),
                    }}
                  />
                </button>
              </TranslatedLink>
            </section>
          </div>
        </div>
      </div>
      <video loop autoPlay muted playsInline className="video-element">
        <source src={homevideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="container">
        <div className="row">
          <div className="col-12 py-5 barlowregular">
            <h1 className="h1_50">
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "HOME.TEXT4" }),
                }}
                ref={text4}
              />
            </h1>
            <h2 className="h1_40">
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "HOME.TEXT5" }),
                }}
                ref={text5}
              />
            </h2>
            <div className="my-5 txt-main">
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "HOME.TEXT6" }),
                }}
                ref={text6}
              />
            </div>
            <section className="d-flex justify-content-center justify-content-lg-start">
              <TranslatedLink to={RUTAS.PRODUCTOS}>
                <button className="my-3 button-lightgrey" ref={btn2}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: "HOME.MOREINFO" }),
                    }}
                  />
                </button>
              </TranslatedLink>
            </section>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <Slideshow />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0 bggrey py-2 bg-nuestrosmundos"></div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6 px-0 mundo-tequila barlowbold py-5">
            <div className="content">
              <div className="mundotitle px-5">
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "HOME.MUNDOTEQUILA" }),
                  }}
                />
              </div>
              <div className="submundo-tequila">
                <div className="mundosubtitle px-5 my-1">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: "HOME.SABORMEXICO" }),
                    }}
                  />
                </div>
                <div className="mundotext px-5">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: "HOME.SUBSABORMEXICO" }),
                    }}
                  />
                </div>
                <Link
                  to={RUTAS.SEARCHPRODUCT[lang][0].replace(
                    ":categoria",
                    formatUrlString(
                      lang === "es" ? "mundo-tequila" : "tequila-world"
                    )
                  )}
                  className="my-3 mx-5 custom-link link-worlds"
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: "HOME.MOREINFO" }),
                    }}
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 px-0 mundo-cocteleria barlowbold py-5">
            <div className="content">
              <div className="mundotitle px-5">
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "HOME.MUNDOCOCTELERIA" }),
                  }}
                />
              </div>
              <div className="submundo-cocteleria">
                <div className="mundosubtitle px-5 my-1">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: "HOME.SIROPESYMIXERS" }),
                    }}
                  />
                </div>
                <div className="mundotext px-5">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "HOME.SUBSIROPESYMIXERS",
                      }),
                    }}
                  />
                </div>
                <Link
                  to={RUTAS.SEARCHPRODUCT[lang][0].replace(
                    ":categoria",
                    formatUrlString(
                      lang === "es" ? "mundo-cocteleria" : "cocktail-world"
                    )
                  )}
                  className="my-3 mx-5 custom-link link-worlds"
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: "HOME.MOREINFO" }),
                    }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 py-5 text-center">
            <div className="title-rwss barlowregular d-flex flex-column flex-md-row align-items-center justify-content-center">
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "HOME.NOVEDADESLINKEDIN" }),
                }}
                ref={text7}
              />
              <img
                src={icolinkedin}
                alt="Nuestros mundos"
                className="ico-rrss align-self-center mt-3 mt-md-0"
              />
            </div>
            <div className="d-flex justify-content-center">
              <a
                href="https://www.linkedin.com/company/destileriasjborrajo/"
                className="link"
                target="_blank"
              >
                <button
                  className="my-3 mx-5 button-lightgrey d-flex justify-content-around align-items-center button-insta"
                  ref={btn3}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: "HOME.SIGUENOS" }),
                    }}
                  />
                </button>
              </a>
            </div>
            <Linkedin />
          </div>
        </div>
        <div className="row">
          <div className="col-12 py-5 text-center">
            <div className="title-rwss barlowregular d-flex flex-column flex-md-row align-items-center justify-content-center">
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "HOME.NOVEDADESINSTAGRAM" }),
                }}
                ref={text8}
              />
              <img
                src={icoinstagram}
                alt="Nuestros mundos"
                className="ico-rrss align-self-center mt-3 mt-md-0"
              />
            </div>
            <div className="d-flex justify-content-center">
              <a
                href="https://www.instagram.com/mexcream/"
                className="link"
                target="_blank"
              >
                <button
                  className="my-3 mx-5 button-lightgrey d-flex justify-content-around align-items-center button-insta"
                  ref={btn3}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: "HOME.SIGUENOS" }),
                    }}
                  />
                  <img src={minimex} className="img-fluid icoinsta mx-3" alt="instagram" title="instagram" />
                </button>
              </a>
            </div>
            <Instagram name="mex" />
            <div className="d-flex justify-content-center">
              <a
                href="https://www.instagram.com/sanzcocktails/"
                className="link"
                target="_blank"
              >
                <button
                  className="my-3 mx-5 button-lightgrey d-flex justify-content-around align-items-center button-insta"
                  ref={btn4}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: "HOME.SIGUENOS" }),
                    }}
                  />
                  <img
                    src={minisanz}
                    className="img-fluid icoinsta mx-3"
                    style={{ margin: "auto" }}
                    alt="instagram" title="instagram"
                  />
                </button>
              </a>
            </div>
            <Instagram name="sanz" />
            <div className="d-flex justify-content-center">
              <a
                href="https://www.instagram.com/gueyhotspicy/"
                className="link"
                target="_blank"
              >
                <button
                  className="my-3 mx-5 button-lightgrey d-flex justify-content-around align-items-center button-insta"
                  ref={btn5}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: "HOME.SIGUENOS" }),
                    }}
                  />
                  <img
                    src={miniguey}
                    className="img-fluid icoinsta mx-3"
                    style={{ height: "14px" }}
                    alt="instagram" title="instagram"
                  />
                </button>
              </a>
            </div>
            <Instagram name="guey" />
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Home);

export const RUTAS = {
  BASE: {
    es: "/",
    en: "/en",
  },
  ABOUT: {
    es: "/el-hub",
    en: "/en/the-hub",
  },
  LADESTILERIA: {
    es: "/ladestileria",
    en: "/en/the-distillery",
  },
  PRODUCTOS: {
    es: "/productos",
    en: "/en/products",
  },
  CONTACTO: {
    es: "/contacto",
    en: "/en/contact",
  },
  SEARCHPRODUCT: {
    es: [
      "/productos/:categoria",
      "/productos/:categoria/:producttype",
      "/productos/:categoria/:producttype/:brand",
      "/productos/:categoria/:producttype/:brand/:product",
    ],
    en: [
      "/en/products/:categoria",
      "/en/products/:categoria/:producttype",
      "/en/products/:categoria/:producttype/:brand",
      "/en/products/:categoria/:producttype/:brand/:product",
    ],
  },
  RESULTSPAGE: {
    es: "/resultados",
    en: "/en/results",
  },
  DESCARGAS: {
    es: "/descargas",
    en: "/en/downloads",
  },
  SITEMAP: {
    es: "/mapa-sitio",
    en: "/en/site-map",
  },
  LEGAL: {
    es: "/aviso-legal",
    en: "/en/legal-notice",
  },
  PRIVACIDAD: {
    es: "/politica-privacidad",
    en: "/en/privacy-policy",
  },
  COOKIES: {
    es: "/politica-cookies",
    en: "/en/cookies-policy",
  },
  QRS: {
    es: "/qr",
    en: "/en/qr",
  },
};

import React, { useState, useEffect } from "react";
import { SERVER_URL } from "../../utils";
import { FormattedMessage } from "react-intl";
import pdf from "../../assets/ico-pdf.png";
import { getProducts } from "../../api/products";
import { getProductTypes } from "../../api/productypes";
import { getCategories } from "../../api/categories";
import { useLang } from "../../translations/i18n";
import { Link, useParams } from "react-router-dom";
import { useBrands } from "../../hooks/useBrands";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getBrands } from "../../api/brands";

const formatUrlString = (str, url = null) => {
  if (url)
    return encodeURIComponent(
      str
        ?.normalize("NFD")
        ?.replace(/[\u0300-\u036f]/g, "")
        ?.toLowerCase()
        ?.split(" ")
        ?.join("-")
    );
  else
    return str
      ?.normalize("NFD")
      ?.replace(/[\u0300-\u036f]/g, "")
      ?.toLowerCase();
};

export default function Product({ product, productType }) {
  const lang = useLang();
  const [products, setProducts] = useState(null);
  const [productTypes, setProductTypes] = useState(null);
  const [categories, setCategories] = useState(null);
  const { categoria } = useParams();
  const { producttype } = useParams();
  const [productPath, setProductPath] = useState(
    lang == "en" ? "en/products" : "productos"
  );
  const [selectedBrands, setSelectedBrands] = useState([]);
  const { handleFetchBrands } = useBrands();

  useEffect(() => {
    getBrands().then((resp) => {
      getProducts()
        .then((res) => {
          const relatedProducts = product?.relatedProducts || [];
          const filteredProducts = res.data.filter((p) =>
            relatedProducts.includes(p._id)
          );
          const filteredBrands = resp.data.filter((b) =>
            filteredProducts.some((p) => p.brand === b._id)
          );
          setProducts(filteredProducts);
          setSelectedBrands(filteredBrands);
        })
        .catch((e) => console.log(e));
    });
    getProductTypes().then((res) => {
      setProductTypes(res.data);
    });
    getCategories().then((res) => {
      setCategories(res.data);
    });
  }, [product]);

  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: products?.length > 3 ? 3 : products?.length,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 1500,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleProductCategory = (p) => {
    const productType = productTypes.find((pt) => pt._id === p.type);
    const category = categories.find((c) => c._id === productType?.category);
    return formatUrlString(category?.name[lang], "url");
  };

  const handleProductType = (p) => {
    const productType = productTypes.find((pt) => pt._id === p.type);
    return formatUrlString(productType?.fullName[lang], "url");
  };

  return (
    <div>
      {product?.blocks?.map((b, index) => {
        const isEven = index % 2 === 0;
        if (b.image.length > 0) {
          return (
            <div className="row" key={index}>
              {isEven ? (
                <>
                  <div
                    className="col-12 col-lg-6 bgproductos2"
                    style={{
                      backgroundImage: `url(${SERVER_URL}/${b?.image})`,
                    }}
                  />
                  <div className="col-12 offset-lg-1 col-lg-4 d-flex align-items-center justify-content-center py-5">
                    <div
                      className="col-12 offset-lg-1 col-lg-8"
                      dangerouslySetInnerHTML={{ __html: b.text[lang] }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12 offset-lg-1 col-lg-4 d-flex align-items-center justify-content-center py-5">
                    <div
                      className="col-12 offset-lg-1 col-lg-8"
                      dangerouslySetInnerHTML={{ __html: b.text[lang] }}
                    />
                  </div>
                  <div
                    className="col-12 col-lg-6 bgproductos2 offset-lg-1"
                    style={{
                      backgroundImage: `url(${SERVER_URL}/${b?.image})`,
                    }}
                  />
                </>
              )}
            </div>
          );
        }
      })}
      {product?.subtitle &&
        Object.keys(product?.subtitle).length === 0 &&
        product.footerImage && (
          <div className="row">
            <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
              {product?.footerImage ? (
                <img
                  className="h-75"
                  src={`${SERVER_URL}/${product?.footerImage}`}
                />
              ) : (
                ""
              )}
            </div>
            <div className="col-12 offset-lg-1 col-lg-4 d-flex flex-column justify-content-center py-5">
              <h2 className="heeboextrabold">
                {product?.fullName[lang].toUpperCase()}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: product?.description?.[lang],
                }}
              />
              {product?.pdfFile && (
                <div className="d-flex direction-row align-items-center">
                  <a href={product?.pdfFile} target="_blank">
                    <img
                      src={pdf}
                      alt="pdf_prod"
                      style={{ cursor: "pointer" }}
                    />
                    <p className="mx-2 align-self-center">{product?.pdfName}</p>
                  </a>
                </div>
              )}
            </div>
          </div>
        )}
      {products?.length > 0 && (
        <div className="container-fluid">
          <h4 className="my-2 black modal-title">
            <FormattedMessage id="PRODUCTS.PRODUCTOSRELACIONADOS" />
          </h4>
          <Slider {...settings}>
            {products?.map((p, index) => {
              const selectedBrand = selectedBrands.find(
                (brand) => brand._id === p.brand
              );
              return (
                <div key={index} className="product-slide">
                  <Link
                    to={`/${productPath}/${handleProductCategory(
                      p
                    )}/${handleProductType(p)}/${formatUrlString(
                      selectedBrand?.fullName,
                      "url"
                    )}/${formatUrlString(p.fullName[lang], "url")}`}
                    className="d-flex flex-column align-items-center justify-content-center text-decoration-none text-dark"
                  >
                    <img
                      className="imgproductlist p-4"
                      src={`${SERVER_URL}/${
                        p?.footerImage
                          ? p?.footerImage
                          : "uploads/brands/no_disponible.png"
                      }`}
                      alt={p?.fullName[lang]}
                      onMouseOver={(e) => {
                        e.target.style.transform = "scale(1.05)";
                      }}
                      onMouseOut={(e) => {
                        e.target.style.transform = "scale(1.00)";
                      }}
                    />
                    <strong className="my-3 text-center">
                      {p?.fullName[lang]?.toUpperCase()}
                    </strong>
                  </Link>
                </div>
              );
            })}
          </Slider>
        </div>
      )}
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { SERVER_URL } from "../../utils";

export default function BrandCap({ brand }) {
  const backgroundImageUrl = `${SERVER_URL}/${brand?.mainImg}`;
  const divStyle = {
    backgroundImage: `url(${backgroundImageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    minHeight: "100vh",
    backgroundRepeat: "no-repeat",
  };

  return <div className="bgproductos3 p-0 m-0" style={divStyle} />;
}

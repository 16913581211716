import { getThehubs } from '../api/thehub'
import { useMemo } from 'react'

export const useThehubs = () => {

	return useMemo(() => {

		const handleFetchThehubs = async ({ setLoading = () => {} }) => {

			setLoading(true)

			try {
				const { data } = await getThehubs()

				if (!data)
					return {}

				return data

			} catch (error) {
				/* gestionar el error */
				console.log('could not fetch thehubs ', error)
				return { error }
			} finally {
				setLoading(false)
			}
		}

		return {
			handleFetchThehubs
		}

	}, [])
}

import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import Cookies from "js-cookie";
import logo from "../assets/logoblack.png";
import { useLang } from "../translations/i18n";

function AgeVerification() {
  const [show, setShow] = useState(false);
  const [language, setLanguage] = useState(useLang());

  useEffect(() => {
    if (Cookies.get("isEighteenOrOlder")) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, []);

  const handleConfirm = (confirmed) => {
    if (confirmed) {
      Cookies.set("isEighteenOrOlder", "true", { expires: 7 });
      setShow(false);
    } else {
      alert("Please confirm that you're 18 or older to continue.");
    }
  };

  if (!show) return null;

  return (
    <>
      <div className="overlay-age" />
      <div className="custom-modal" tabIndex="-1">
        <div className="dialog">
          <div className="content-age">
            <div className="header-age">
              <img src={logo} alt="logo" className="centered-logo" />
            </div>
            <div className="body-age">
              <h4>
                {language === "es" ? (
                  <FormattedMessage
                    id="ageVerification.spanishText"
                    defaultMessage="Soy mayor de 18 años"
                  />
                ) : (
                  <FormattedMessage
                    id="ageVerification.englishText"
                    defaultMessage="I am of legal age"
                  />
                )}
              </h4>
              <div className="d-flex flex-row">
                <button
                  type="button"
                  className="btn-option yes-btn"
                  onClick={() => handleConfirm(true)}
                >
                  {language === "es" ? (
                    <FormattedMessage
                      id="ageVerification.spanishText"
                      defaultMessage="SÍ"
                    />
                  ) : (
                    <FormattedMessage
                      id="ageVerification.englishText"
                      defaultMessage="YES"
                    />
                  )}
                </button>
                <button
                  type="button"
                  className="btn-option no-btn"
                  onClick={() => handleConfirm(false)}
                >
                  NO
                </button>
              </div>
              <small className="info-text">
                {language === "es" ? (
                  <FormattedMessage
                    id="ageVerification.spanishInfoText"
                    defaultMessage="Para visitar www.destileriasjborrajo.net tienes que tener la edad mínima legal para consumir alcohol en el país que resides."
                  />
                ) : (
                  <FormattedMessage
                    id="ageVerification.englishInfoText"
                    defaultMessage="To visit www.destileriasjborrajo.net you must meet the minimal legal drinking age requirement of your country of residence."
                  />
                )}
              </small>
            </div>
            <div className="footer-age">
              <small>
                <FormattedMessage
                  id="ageVerification.footerText"
                  defaultMessage="2023 © Internacional de Comercio y Destilerias J. Borrajo S.A."
                />
              </small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AgeVerification;

import { getDownloads } from '../api/download'
import { useMemo } from 'react'

export const useDownloads = () => {

	return useMemo(() => {

		const handleFetchDownloads = async ({ setLoading = () => {} }) => {

			setLoading(true)

			try {
				const { data } = await getDownloads()

				if (!data)
					return {}

				return data

			} catch (error) {
				/* gestionar el error */
				console.log('could not fetch downloads ', error)
				return { error }
			} finally {
				setLoading(false)
			}
		}

		return {
			handleFetchDownloads
		}

	}, [])
}

import React from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useLang } from "../../translations/i18n";
import { FormattedMessage } from "react-intl";

function CookieConsentPopUp({ intl }) {
  const lang = useLang();
  const cookiePolicyUrl =
    lang === "es" ? "/politica-cookies" : "/en/cookie-policy";
  const buttonText = lang === "es" ? "Aceptar" : "I Understand";
  const declineButtonText = lang === "es" ? "Rechazar" : "Decline";
  const message =
    lang === "es"
      ? `Leete nuestra <a href="${cookiePolicyUrl}">política de cookies</a> para más información.`
      : `Read our <a href="${cookiePolicyUrl}">cookie policy</a> for more information.`;

  return (
    <CookieConsent
      location="bottom"
      buttonText={buttonText}
      declineButtonText={declineButtonText}
      cookieName="userAcceptedCookies"
      style={{ background: "#2B373B" }}
      buttonStyle={{
        backgroundColor: "#838383",
        color: "white",
        fontSize: "13px",
      }}
      declineButtonStyle={{
        background: "white",
        fontSize: "13px",
        color: "#535353",
      }}
      expires={150}
    >
      <small dangerouslySetInnerHTML={{ __html: message }}></small>
    </CookieConsent>
  );
}

export default CookieConsentPopUp;

import { getBrands } from '../api/brands'
import { useMemo } from 'react'

export const useBrands = () => {

	return useMemo(() => {

		const handleFetchBrands = async ({ featured, tag, setLoading = () => {} }) => {
			setLoading(true)

			try {
				const { data } = await getBrands(tag, featured)

				/* gestionar quan la resposta es buida (si es dona el cas) */
				if (!data)
					return []

				return data || []

			} catch (error) {
				/* gestionar el error */
				console.log('could not fetch brands ', error)
				return { error }
			} finally {
				setLoading(false)
			}
		}

		return {
			handleFetchBrands
		}

	}, [])
}

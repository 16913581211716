import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../utils";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";
import { RUTAS } from "../constants/routes.constants";
import TranslatedLink from "../components/navigation/TranslatedLink";
import clsx from "clsx";
import icomail2 from "../assets/ico-mail-2.png";
import icoloc2 from "../assets/ico-loc-2.png";

function getEmptyContact() {
  return {
    name: "",
    email: "",
    subject: "",
    message: "",
  };
}

function Contacto({ intl }) {
  const [contact, setContact] = useState(getEmptyContact());

  const lang = useLang();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const text1 = useRef(null);
  const text2 = useRef(null);
  const text3 = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("txt-animation");
          } else {
            entry.target.classList.remove("txt-animation");
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      }
    );

    [text1, text2, text3].forEach((text) => {
      if (text.current) {
        observer.observe(text.current);
      }
    });

    return () => {
      [text1, text2, text3].forEach((text) => {
        if (text.current) {
          observer.unobserve(text.current);
        }
      });
    };
  }, []);

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "CONTACT.TITLESEO" }) || ""}
        description={intl.formatMessage({ id: "CONTACT.DESCRIPTIONSEO" }) || ""}
      />
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-12 col-lg-6 px-0" style={{ overflow: "hidden" }}>
            <div className="bg-contacta"></div>
          </div>
          <div className="col-12 col-lg-6 p-5 d-flex align-items-center">
            <div>
              <h1 className="barlowmedium h1_75">
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "CONTACT.CONTACT" }),
                  }}
                  ref={text1}
                />
              </h1>
              <h2 className="barlowmedium mb-5 h2_43">
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "CONTACT.WITHUS" }),
                  }}
                  ref={text2}
                />
              </h2>
              <p className="heeboregular my-4">
                INTERNACIONAL DE COMERCIO Y DESTILERÍAS{" "}
                <span className="red">J. BORRAJO S. A</span>
              </p>
              <div className="position-relative heeboregular">
                <img src={icoloc2} className="position-absolute" />
                <div className="mx-5">
                  C/Francisco Redondo, 10 - 45600 Talavera de la Reina
                  <br />
                  <a href="tel:+34925802004" className="no-underline-black">
                    +34 925 80 20 04
                  </a>
                  <br />
                </div>
              </div>
              <div className="position-relative my-3 heeboextrabold">
                <img src={icomail2} className="position-absolute" />
                <div className="mx-5">
                  <a
                    href="mailto:comunicacion@borrajo.com"
                    className="no-underline-black"
                  >
                    comunicacion@jborrajo.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 order-2 order-lg-1 p-5 d-flex align-items-center">
            <div ref={text3} className="">
              <h1 className="barlowmedium mb-5">
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "CONTACT.QUIERESFORMAR" }),
                  }}
                />
              </h1>
              <button
                onClick={() =>
                  (window.location.href = "mailto:comunicacion@borrajo.com")
                }
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "CONTACT.CONTACTUS" }),
                  }}
                />
              </button>
            </div>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-2 px-0 bg-contacta-2"></div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Contacto);

import React from "react";
import { SERVER_URL } from "../../utils";

const TypeCap = ({ type }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${SERVER_URL}/${type?.mainImg})`,
        backgroundSize: "cover",
        backgroundPosition: "left center",
        width: "100%",
        minHeight: "100vh",
        backgroundRepeat: "no-repeat",
      }}
    />
  );
};

export default TypeCap;

import axios from 'axios'
import { API } from '../../utils/index'
const instance = axios.create()


export const getThehubs = async () => {
	const _endpoint = 'thehub'
	const URL = `${API}/${_endpoint}`
	return instance.get(URL)
}

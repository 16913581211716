import React, { useEffect, useState, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getQRById, updateQRById } from "../api/QR";

const QRS = () => {
  const [error, setError] = useState(null);
  const location = useLocation();
  const hasFetched = useRef(false);

  useEffect(() => {
    if (hasFetched.current) return;
    hasFetched.current = true;

    const queryParams = new URLSearchParams(window.location.search);
    const qrId = queryParams.toString().replace(/[^a-zA-Z]/g, "");
    if (qrId) {
      fetchRedirectUrl(qrId);
    } else {
      setError("THE QR CODE IS NOT VALID, TRY LATER.");
    }
  }, [location]);

  const fetchRedirectUrl = async (qrId) => {
    try {
      const { data } = await getQRById(qrId);
      console.log(data);
      const redirectUrl = data.redirectURL;

      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        setError("QR code not found.");
      }
    } catch (err) {
      setError("Could not load the QR page correctly, please try again later.");
    }
  };

  return (
    <div className="container">
      {error ? (
        <div className="alert alert-danger mt-5">{error}</div>
      ) : (
        <div className="d-flex flex-column align-items-center justify-content-center m-5 p-5">
          <h2 className="sr-only">Loading...</h2>
          <div className="spinner-border mt-5" role="status"></div>
        </div>
      )}
    </div>
  );
};

export default QRS;

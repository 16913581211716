import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { SERVER_URL } from "../utils";
import { useLang } from "../translations/i18n";
import { getProducts } from "../api/products";
import { getProductTypes } from "../api/productypes";
import { getCategories } from "../api/categories";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const formatUrlString = (str, url = null) => {
  if (url)
    return encodeURIComponent(
      str
        ?.normalize("NFD")
        ?.replace(/[\u0300-\u036f]/g, "")
        ?.toLowerCase()
        ?.split(" ")
        ?.join("-")
    );
  else
    return str
      ?.normalize("NFD")
      ?.replace(/[\u0300-\u036f]/g, "")
      ?.toLowerCase();
};

function BrandsPage() {
  let { state } = useLocation();
  let brand = state.brand;
  const [brandProds, setBrandProds] = useState([]);
  const lang = useLang();
  const productPath = lang === "en" ? "en/products" : "productos";
  const backgroundImageUrl = `${SERVER_URL}/${brand?.mainImg}`;
  const divStyle = {
    backgroundImage: `url(${backgroundImageUrl})`,
  };

  useEffect(() => {
    Promise.all([getProducts(), getCategories(), getProductTypes()])
      .then(([productsRes, categoriesRes, productTypesRes]) => {
        if (productsRes.status === 200) {
          const products = productsRes.data.filter(
            (p) => p.brand === brand._id
          );
          const categories = categoriesRes.data;
          const productTypes = productTypesRes.data;

          const productsWithDetails = products.map((product) => {
            const productType = productTypes.find(
              (type) => type._id === product.type
            );
            const category = categories.find(
              (cat) => cat._id === productType.category
            );

            const categoria = formatUrlString(category.name[lang], "url");
            const producttype = formatUrlString(
              productType.fullName[lang],
              "url"
            );
            const brandName = formatUrlString(brand.fullName, "url");
            const productName = formatUrlString(product.fullName[lang], "url");
            const productUrl = `/${productPath}/${categoria}/${producttype}/${brandName}/${productName}`;

            return {
              ...product,
              url: productUrl,
            };
          });

          setBrandProds(productsWithDetails);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [brand._id]);

  const settings = {
    speed: 500,
    slidesToShow: brandProds?.length > 3 ? 3 : brandProds?.length,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 1500,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="col-12 col-lg-8 p-0 m-0 bggrey">
        <div className="bgproductos3 p-0 m-0" style={divStyle} />
      </div>
      <div className="container-fluid">
        <Slider {...settings}>
          {brandProds?.map((p, index) => (
            <div key={index} className="product-slide">
              <Link
                to={p.url}
                className="d-flex flex-column align-items-center justify-content-center text-decoration-none text-dark"
              >
                <img
                  className="imgproductlist p-4"
                  src={`${SERVER_URL}/${
                    p?.footerImage
                      ? p?.footerImage
                      : "uploads/brands/no_disponible.png"
                  }`}
                  alt={p?.fullName[lang]}
                  style={{
                    cursor: "pointer",
                    // width: "300px",
                    // height: "300px",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.transform = "scale(1.05)";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.transform = "scale(1.00)";
                  }}
                />
                <strong className="my-3 text-center">
                  {p?.fullName[lang]?.toUpperCase()}
                </strong>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}

export default BrandsPage;

import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../utils";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";
import clsx from "clsx";
import { RUTAS } from "../constants/routes.constants";
import TranslatedLink from "../components/navigation/TranslatedLink";
import { getCategories } from "../api/categories";

function Productos({ intl }) {
  const lang = useLang();
  const [categories, setCategories] = useState([]);
  const [specialCategories, setSpecialCategories] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [textMessage, setTextMessage] = useState("");
  const [leftMargin, setLeftMargin] = useState("");

  useEffect(() => {
    getCategories()
      .then((res) => {
        if (res.status === 200) {
          setSpecialCategories(
            res.data.filter(
              (c) => c.categoriamundotequila || c.categoriamundococteleria
            )
          );
          setCategories(
            res.data.filter(
              (c) => !c.categoriamundotequila && !c.categoriamundococteleria
            )
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const categoryBackgrounds = {
    "Alcoholic drinks": "bg-alcoholicos",
    "Non alcoholic drinks": "bg-noalcoholicos",
    Wines: "bg-vinos",
    "Tequila World": "bg-mundotequila",
    "Cocktail World": "bg-mundococteleria",
  };

  let count = 0;

  const formatUrlString = (str) => {
    const withoutAccents = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return withoutAccents.toLowerCase().replace(/\s+/g, "-");
  };

  const handleHovered = (value, section = "") => {
    setIsHovered(value);
    if (section === "lateral-left") {
      setTextMessage(intl.formatMessage({ id: "PRODUCTS.BUSCARPORMARCAS" }));
      setLeftMargin("lateral-left");
    } else if (section === "lateral-right") {
      setTextMessage(intl.formatMessage({ id: "PRODUCTS.BUSCARPORMUNDOS" }));
      setLeftMargin("lateral-right");
    } else {
      setTextMessage(intl.formatMessage({ id: "PRODUCTS.BUSCARPORCATEGORIAS" }));
      setLeftMargin("middle");
    }
  };

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "PRODUCTS.TITLESEO" }) || ""}
        description={intl.formatMessage({ id: "PRODUCTS.DESCRIPTIONSEO" }) || ""}
      />
      <div className="container-fluid">
        <div
          className={`hover-text ${leftMargin}`}
          style={{
            opacity: isHovered ? 1 : 0,
          }}
        >
          {textMessage}
        </div>
        <div className="row text-center">
          <Link
              className="col-12 col-md-4 px-0 bg-nuestrosprod s1 text-decoration-none d-flex align-items-center justify-content-center orange"
              onMouseOver={() => handleHovered(true, "lateral-left")}
              onMouseLeave={() => handleHovered(false)}
                to={`/productos/${encodeURIComponent("nuestras-marcas")}`}
          >
            <div
              className="barlowbold producto-title-shadow  text-white"
            >
              {intl.formatMessage({ id: "PRODUCTS.NUESTRASMARCAS" }) || ""}
            </div>
          </Link>
          <div
            className="col-12 col-md-4 mx-0"
            onMouseEnter={() => handleHovered(true, "middle")}
            onMouseLeave={() => handleHovered(false)}
          >
            <div className="container-fluid px-0">
              <div className="row">
                {categories.map((category) => (
                  <Link
                    className={`col-12 ${
                      categoryBackgrounds[category.name.en]
                    } d-flex align-items-center justify-content-center text-decoration-none bg-nuestrosprod s2 gray`}
                    to={RUTAS.SEARCHPRODUCT[lang][0].replace(
                      ":categoria",
                      formatUrlString(category.name[lang])
                    )}
                    key={category.name.en}
                  >
                    <div
                      key={category}
                      className="barlowbold producto-title-shadow text-white"
                    >
                      {category.name[lang].toUpperCase()}
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-4 mx-0"
            onMouseEnter={() => handleHovered(true, "lateral-right")}
            onMouseLeave={() => handleHovered(false)}
          >
            <div className="container-fluid px-0 h-100">
              <div className="row h-100">
                {specialCategories.map((category) => (
                  <Link
                    className={`col-12 ${
                      categoryBackgrounds[category.name.en]
                    } d-flex align-items-center justify-content-center bg-nuestrosprod text-decoration-none s3 blue`}
                    to={RUTAS.SEARCHPRODUCT[lang][0].replace(
                      ":categoria",
                      formatUrlString(category.name[lang])
                    )}
                    key={category.name.en}
                  >
                    <div
                      key={category}
                      className="barlowbold producto-title-shadow text-white"
                    >
                      {category.name[lang].toUpperCase()}
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Productos);

import axios from 'axios'
import { API } from '../../utils/index'
const instance = axios.create()


export const getDownloads = async () => {
	const _endpoint = 'download'
	const URL = `${API}/${_endpoint}`
	return instance.get(URL)
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { getTokens } from "../../api/tokens";

function Instagram({ name }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    getTokens(name)
      .then((res) => {
        if (res.status === 200) {
          setItems(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div className="row justify-content-center my-5">
      {items &&
        items.length &&
        items?.map((i, key) => (
          <div className="col-md-3 col-12 instagram-post my-1" key={key}>
            <div className="card">
              {i.permalink.includes("reel") ? (
                <a href={i.permalink} target="_blank" rel="noopener noreferrer">
                  <video
                    src={i.mediaUrl}
                    className="card-img-top"
                    alt="Instagram Reel"
                    description="Instagram Post"
                    loop
                    autoPlay
                    muted
                    playsInline
                  />
                </a>
              ) : (
                <a href={i.permalink} target="_blank" rel="noopener noreferrer">
                  <img
                    src={i.mediaUrl}
                    className="card-img-top"
                    alt="Instagram Post"
                    description="Instagram Post"
                  />
                </a>
              )}
            </div>
          </div>
        ))}
    </div>
  );
}

export default Instagram;

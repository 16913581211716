import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useBrands } from "../../hooks/useBrands";
import { SERVER_URL } from "../../utils";
import { useLang } from "../../translations/i18n";
import { getProductTypes } from "../../api/productypes";
import { getCategories } from "../../api/categories";
import { getProducts } from "../../api/products";

export default function Logos() {
  const lang = useLang();
  const [logos, setLogos] = useState([]);
  const [productTypes, setProductTypes] = useState(null);
  const [categories, setCategories] = useState(null);
  const [products, setProducts] = useState(null);
  const [dataBrands, setDataBrands] = useState([]);
  const { handleFetchBrands } = useBrands();
  const [productPath, setProductPath] = useState(
    lang == "en" ? "en/products" : "productos"
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    const asyncFetchBrands = async () => {
      const dataBrands = await handleFetchBrands({ featured: true });
      if (!dataBrands?.length) return;

      const filteredBrands = dataBrands
        .filter((b) => b.logo && b.logo !== "")
        .sort((a, b) => a.order - b.order)
        .reduce((acc, brand) => {
          const existingBrand = acc.find(
            (b) => b.fullName.trim() === brand.fullName.trim()
          );
          if (!existingBrand) {
            acc.push(brand);
          }
          return acc;
        }, []);

      setDataBrands(filteredBrands);
    };
    getProductTypes().then((res) => {
      setProductTypes(res.data);
    });
    getCategories().then((res) => {
      setCategories(res.data);
    });
    getProducts().then((res) => {
      setProducts(res.data);
    });
    asyncFetchBrands();
  }, []);

  const formatUrlString = (str) => {
    const withoutAccents = str
      ?.normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    return withoutAccents?.toLowerCase().replace(/\s+/g, "-");
  };

  const handleBrandInfo = (b, infoType) => {
    const getProductInfo = (productId, infoType) => {
      const product = products?.find((p) => p.brand === b._id);
      const productType = productTypes?.find((pt) => pt._id === product?.type);
      switch (infoType) {
        case "category":
          const category = categories?.find(
            (c) => c._id === productType?.category
          );
          return category?.name[lang];
        case "type":
          return productType?.fullName[lang];
        default:
          return null;
      }
    };

    let info = null;
    switch (infoType) {
      case "category":
        info = getProductInfo(b._id, "category");
        break;
      case "type":
        info = getProductInfo(b._id, "type");
        break;
      default:
        break;
    }
    return formatUrlString(info, "url");
  };

  const getLogos = () => {
    const rows = [];
    for (let i = 0; i < dataBrands.length; i += 4) {
      const rowLogos = dataBrands.slice(i, i + 4);
      const row = (
        <div
          className="row d-flex justify-content-center align-items-center text-center p-3"
          key={i}
        >
          {rowLogos.map((logo, index) => (
            <div className="col-6 col-lg-3" key={index}>
              <Link
                key={i}
                to={`/${productPath}/${handleBrandInfo(
                  logo,
                  "category"
                )}/${handleBrandInfo(logo, "type")}/${formatUrlString(
                  logo.fullName
                )}`}
                className="barlowbold productotitle text-decoration-none text-white"
                state={{ brand: logo }}
              >
                <img
                  src={`${SERVER_URL}/${
                    logo.logo ? logo.logo : "uploads/brands/no_disponible.png"
                  }`}
                  className="logobrand"
                  alt={logo.fullName}
                />
              </Link>
            </div>
          ))}
        </div>
      );
      rows.push(row);
    }
    return <>{rows}</>;
  };

  return <div className="container">{getLogos()}</div>;
}

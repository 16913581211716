import React from "react";
import { Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import slider1 from "../assets/slider-home-1.jpg";
import slider2 from "../assets/slider-home-2.jpg";
import slider3 from "../assets/slider-home-3.jpg";
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa6";

const divStyle = {
  display: "flex",
};

const buttonStyle = {
  width: "40px",
  height: "40px",
  color: "white",
  padding: "4px",
};

const properties = {
  prevArrow: <FaChevronLeft style={{ ...buttonStyle }} />,
  nextArrow: <FaChevronRight style={{ ...buttonStyle }} />,
  autoplay: true,
  Infinity: true,
  pauseOnHover: false,
  duration: 1500,
  indicators: false
};

const slideImages = [
  {
    path: slider1,
    caption: "Slide 1",
  },
  {
    path: slider2,
    caption: "Slide 2",
  },
  {
    path: slider3,
    caption: "Slide 3",
  },
];

export default function Slideshow() {
  return (
    <div className="slide-container">
      <Zoom scale={1.4} indicators={true} {...properties}>
        {slideImages.map((slideImage, index) => (
          <div key={index}>
            <div
              style={{
                ...divStyle,
                backgroundImage: `url(${slideImage.path})`,
              }}
              className="slide-image"
            />
          </div>
        ))}
      </Zoom>
    </div>
  );
}

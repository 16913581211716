import React, { useEffect, useState } from "react";
import { SERVER_URL } from "../../utils";
import { useLang } from "../../translations/i18n";

export default function Brand({ brand, brandType }) {
  const lang = useLang();
  const text = lang === "es" ? "MÁS INFO" : "MORE INFO";

  const videoLink =
    brand?.video?.[lang] || brand?.video?.[lang === "es" ? "en" : "es"] || "";

  return (
    <div>
      {videoLink != "" && (
        <div className="col-12 px-0">
          <video loop autoPlay muted playsInline className="video-element">
            <source src={`${SERVER_URL}/${videoLink}`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      {brand?.blocks?.map((b, index) => {
        const isEven = index % 2 === 0;
        if (b.image.length > 0) {
          return (
            <div className="row" key={index}>
              {isEven ? (
                <>
                  <div
                    className="col-12 col-lg-6 bgproductos order-2 order-lg-1"
                    style={{
                      backgroundImage: `url(${SERVER_URL}/${b?.image})`,
                    }}
                  />
                  <div className="col-12 offset-lg-1 col-lg-4 d-flex flex-column align-items-center justify-content-center my-5 order-1 order-lg-2">
                    <div
                      className="col-12 offset-lg-1 px-4 px-lg-0 col-lg-8"
                      dangerouslySetInnerHTML={{ __html: b.text[lang] }}
                    />
                    {b?.button && b?.button[lang] ? (
                      <button className="my-3 button-lightgrey">
                        <a href={b?.button[lang]} className="button-product">
                          {text}
                        </a>
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12 offset-lg-1 col-lg-4 d-flex flex-column align-items-center my-5 justify-content-center">
                    <div
                      className="col-12 offset-lg-1 px-4 px-lg-0 col-lg-8"
                      dangerouslySetInnerHTML={{ __html: b.text[lang] }}
                    />
                    {b?.button && b?.button[lang] ? (
                      <button className="my-3 button-lightgrey">
                        <a href={b?.button[lang]} className="button-product">
                          {text}
                        </a>
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="col-12 offset-lg-1 col-lg-6 bgproductos2"
                    style={{
                      backgroundImage: `url(${SERVER_URL}/${b?.image})`,
                    }}
                  />
                </>
              )}
            </div>
          );
        }
      })}
    </div>
  );
}

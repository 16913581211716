import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { I18nProvider } from "./translations/I18nProvider";
import { CustomI18nProvider } from "./translations/i18n";
import Rutas from "./Rutas";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { createBrowserHistory } from "history";

const tagManagerArgs = {
  gtmId: "GTM-XXXXXXXX",
  dataLayerName: "PageDataLayer",
};

TagManager.initialize(tagManagerArgs);

//REACT_GA CONFIGURATION
ReactGA.initialize([
  {
    trackingId: "G-DT3E83MB79",
  },
]);

const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.send(location.pathname);
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CustomI18nProvider>
      <I18nProvider>
        <Rutas />
      </I18nProvider>
    </CustomI18nProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

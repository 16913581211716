import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { SERVER_URL } from "../utils";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";
import { RUTAS } from "../constants/routes.constants";
import TranslatedLink from "../components/navigation/TranslatedLink";
import clsx from "clsx";
import icomail2 from "../assets/ico-mail-2.png";
import LateralNav from "../components/navigation/LateralNav";
import Logos from "../components/productSearch/Logos";
import Category from "../components/productSearch/Category";
import BrandCap from "../components/productSearch/BrandCap";
import Brand from "../components/productSearch/Brand";
import ProductCap from "../components/productSearch/ProductCap";
import TypeCap from "../components/productSearch/TypeCap";
import Product from "../components/productSearch/Product";
import BrandsPage from "./BrandsPage";
import { getBrands } from "../api/brands";
import { getProducts } from "../api/products";
import { getProductTypes } from "../api/productypes";
import { getFormats } from "../api/formats";
import { useLocation } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function normalizeString(str) {
  return str
    ?.normalize("NFD")
    ?.replace(/[\u0300-\u036f]/g, "")
    ?.toLowerCase();
}

function SearchProduct({ intl }) {
  const lang = useLang();
  const [brandselec, setBrandSelec] = useState(null);
  const [productselec, setProductSelec] = useState(null);
  const [productTypes, setProductTypes] = useState(null);
  const [prodSelecId, setProdSelecId] = useState(null);
  const [formats, setFormats] = useState([]);
  const { categoria, brand, product, producttype } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [allBrands, setAllBrands] = useState(null);
  const [allProductTypes, setAllProductTypes] = useState(null);
  const [allProducts, setAllProducts] = useState(null);
  const [allFormats, setAllFormats] = useState(null);

  useEffect(() => {
    Promise.all([getBrands(), getProductTypes(), getProducts(), getFormats()])
      .then(([brandsRes, productTypesRes, productsRes, formatsRes]) => {
        if (brandsRes.status === 200) setAllBrands(brandsRes.data);
        if (productTypesRes.status === 200)
          setAllProductTypes(productTypesRes.data);
        if (productsRes.status === 200) setAllProducts(productsRes.data);
        if (formatsRes.status === 200) setAllFormats(formatsRes.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (allBrands?.length > 0) {
      // console.log(
      //   allBrands.filter(
      //     (b) =>
      //       normalizeString(b.fullName) ===
      //       normalizeString(brand?.split("-").join(" "))
      //   )
      // );
      setBrandSelec(
        allBrands?.filter(
          (b) =>
            normalizeString(b.fullName) ===
            normalizeString(brand?.split("-").join(" "))
        )
      );
    }
  }, [allBrands, brand]);

  useEffect(() => {
    if (allProductTypes?.length > 0) {
      setProductTypes(
        allProductTypes?.filter(
          (p) =>
            normalizeString(p.fullName[lang]) ===
            normalizeString(producttype?.split("-").join(" "))
        )
      );
    }
  }, [allProductTypes, producttype]);

  useEffect(() => {
    if (!product || !brandselec || allProducts?.length === 0) {
      setProductSelec(null);
      return;
    }

    const normalizedProduct = normalizeString(product?.split("-").join(" "));
    const prodselec = allProducts.filter(
      (p) =>
        normalizeString(p.fullName[lang]) === normalizedProduct &&
        p.brand === brandselec[0]._id
    );

    if (prodselec.length > 0) {
      setProductSelec(prodselec);
      setProdSelecId(prodselec[0]._id);
    } else {
      setProductSelec(null);
    }
  }, [product, brandselec, allProducts]);

  useEffect(() => {
    if (prodSelecId && allFormats.length > 0) {
      const filteredformats = allFormats?.filter(
        (f) => f.product === prodSelecId
      );
      setFormats(filteredformats?.sort((a, b) => a.order - b.order));
    }
  }, [prodSelecId]);

  // useEffect(() => {
  //   console.log(brandselec);
  //   if (brandselec && brandselec.length > 0)
  //     console.log("BRAND: ", brandselec[0].fullName);
  //   console.log(brandselec?.length > 0);
  //   console.log(!product);
  //   console.log(productselec);
  //   console.log(!productselec?.length);
  // }, [brandselec]);

  // useEffect(() => {
  //   console.log("PRODUCT: ", productselec);
  // }, [productselec]);

  // useEffect(() => {
  //   console.log("CATEGORIA: ", categoria);
  // }, [categoria]);

  // useEffect(() => {
  //   console.log("PRODUCTTYPE: ", productTypes);
  // }, [productTypes]);

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "PRODUCTS.TITLESEO" }) || ""}
        description={intl.formatMessage({ id: "PRODUCTS.DESCRIPTIONSEO" }) || ""}
      />
      <div className="container-fluid">
        <div className="row">
          <LateralNav />
          {(categoria === "nuestras-marcas" || categoria === "our-brands") &&
            !producttype && (
              <div className="col-12 col-lg-8 p-0 m-0 bgwhite">
                <Logos />
              </div>
            )}
          {/* {categoria === "nuestras-marcas" ||
            (categoria === "our-brands" && producttype && <BrandsPage />)} */}
          <div className="col-12 col-lg-8 p-0 m-0">
            {(categoria !== "nuestras-marcas" || categoria !== "our-brands") &&
              !producttype &&
              !brand &&
              !product && <Category catName={categoria} />}
            {brandselec?.length > 0 && !product && !productselec?.length && (
              <BrandCap brand={brandselec[0]} />
            )}
            {productselec?.length > 0 && product && formats && (
              <ProductCap product={productselec[0]} formats={formats} />
            )}
            {productTypes?.length > 0 && !brand && !product && (
              <TypeCap type={productTypes[0]} />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-12 px-0">
            {brandselec?.length > 0 && !product && (
              <Brand brand={brandselec[0]} productType={productTypes[0]} />
            )}
          </div>
          <div className="col-12 col-lg-12">
            {productselec?.length > 0 && product && (
              <Product
                product={productselec[0]}
                productType={productTypes[0]}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(SearchProduct);

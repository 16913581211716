import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../utils";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";
import { RUTAS } from "../constants/routes.constants";
import TranslatedLink from "../components/navigation/TranslatedLink";
import clsx from "clsx";
import icopdf from "../assets/ico-pdf.png";
import icodownload from "../assets/ico-download.png";
import { useDownloads } from "../hooks/useDownloads";

function getEveryNth(arr, nth) {
  const result = [];

  for (let i = 0; i < arr.length; i += nth) result.push(arr.slice(i, i + nth));

  return result;
}

const PdfElem = ({ fullName, file, id }) => {
  return (
    <a href={file} state={{ id: id }} className="link" target="_blank">
      <div className="position-relative my-3 d-flex align-items-center">
        <img
          src={icopdf}
          alt={fullName}
          className="ico-pdf position-absolute"
        />
        <div className="mx-5">{fullName}</div>
      </div>
    </a>
  );
};

function Descargas({ intl }) {
  const [data, setData] = useState([]);
  const [dataDownloads, setDataDownloads] = useState([]);
  const { handleFetchDownloads } = useDownloads();

  const lang = useLang();

  useEffect(() => {
    window.scrollTo(0, 0);
    const asyncFetchDownloads = async () => {
      const dataDownloads = await handleFetchDownloads({ featured: true });
      if (!dataDownloads?.length) return;
      setDataDownloads(
        dataDownloads
          .filter((d) => d.download)
          .sort((a, b) => a.order - b.order)
      );
    };
    asyncFetchDownloads();
  }, []);

  let count = 0;

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "DOWNLOADS.TITLESEO" }) || ""}
        description={intl.formatMessage({ id: "DOWNLOADS.DESCRIPTIONSEO" }) || ""}
      />
      <div className="container-fluid mt-5">
        <div className="row">
          <div
            className="col-4 d-none d-lg-block px-0"
            style={{ overflow: "hidden" }}
          >
            <div className="bg-descargas1"></div>
          </div>
          <div className="col-12 col-lg-4 py-2 px-5">
            <h1 className="barlowmedium h1_75">
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "DOWNLOADS.DOWNLOADS" }),
                }}
              />
            </h1>
            <div className="my-3">
              <div className="row">
                {dataDownloads?.map((elem) => (
                  <PdfElem
                    fullName={elem.fullName && elem.fullName[lang]}
                    file={`${SERVER_URL}/${elem.file?.[lang]}`}
                    id={elem._id}
                  />
                ))}
              </div>
            </div>
          </div>
          <div
            className="col-4 d-none d-lg-block px-0"
            style={{ overflow: "hidden" }}
          >
            <div className="bg-descargas2"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Descargas);
